<template>
  <v-row justify="center" class="fill-height">
    <v-col cols="12" align-self="center" class="rounded-xl">
      <charts :options="currentChartOptions" class="rounded-xl"></charts>
    </v-col>
    <v-col class="py-4"></v-col>
  </v-row>
</template>
<script setup>
import PipelineService from '@/services/pipeline'
import {
  computed,
  getCurrentInstance,
  onMounted,
  ref,
  toRefs,
  watch,
} from 'vue'
import { customChartOptions } from '@/components/tile/distributionchartModel'
import { useCostFormatter } from '@/composables/cost'
const pipelineService = new PipelineService()
const $lang = computed(() => getCurrentInstance().proxy.$lang)
const chartData = ref([])
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  settings: {
    type: Object,
    required: true,
  },
})
const { data, settings } = toRefs(props)
const chartOptions = ref(new customChartOptions())

onMounted(() => {
  chartOptions.value = new customChartOptions()
  fetchData()
})
const currentChartOptions = computed(() => {
  return {
    ...chartOptions.value,
    legend: {
      enabled: true,
    },
    tooltip: {
      formatter: function () {
        let tooltip = `${this.point.name}<br>`
        tooltip += `<b>${this.series.name}</b> : ${useCostFormatter(this.y)}`
        return tooltip
      },
    },
    chart: {
      ...chartOptions.value.chart,
      type: settings.value.distributionChartType || 'pie',
    },
    series: [
      {
        name: $lang.value.dashboard.cost,
        colorByPoint: true,
        data: chartData.value.map((d) => ({
          name: d.value,
          y: d.cost,
          value: d.cost,
        })),
      },
    ],
  }
})

async function fetchData() {
  chartData.value = []
  if (Array.isArray(data.value.data_scope)) {
    await getDistributionChartData(data.value.data_scope[0])
  } else {
    await getDistributionChartData(data.value.data_scope)
  }
}

async function getDistributionChartData() {
  const response = await pipelineService.aggregate(
    'explorer/group_by_filter',
    {
      from: data.value.start_date,
      to: data.value.end_date,
      scope: data.value.data_scope.blocks || data.value.data_scope[0].blocks,
      group: data.value.explorer.group_by,
      offset: 0,
    },
    'GET',
  )
  if (response) {
    chartData.value = response
  }
}
watch(
  data,
  () => {
    fetchData()
  },
  { deep: true },
)
</script>
