<template>
  <v-row align="center" justify="center" class="fill-height">
    <v-col align-self="center" cols="12">
      <div class="text-center">
        <v-card-subtitle>
          {{ $lang.budget.consumption }}
        </v-card-subtitle>
      </div>
      <div class="text-center font-weight-bold">
        <v-hover v-slot="{ hover }">
          <v-progress-circular
            size="120"
            width="15"
            :value="percentage"
            :color="trendColor"
            :rotate="-90"
            class="white--text"
          >
            <v-fade-transition hide-on-leave>
              <div class="text-center" v-show="hover">
                <v-icon large :color="trendColor">
                  {{ `mdi-${trendIcon}` }}
                </v-icon>
              </div>
            </v-fade-transition>

            <v-fade-transition hide-on-leave>
              <div
                :class="`text-center ${trendColor}--text font-weight-bold`"
                v-show="!hover"
              >
                {{ percentage | trend }}
              </div>
            </v-fade-transition>
          </v-progress-circular>
        </v-hover>
      </div>
      <div class="text-center">
        <v-card-subtitle>
          {{ startDate | date }}
          {{ $lang.card.to }}
          {{ endDate | date }}
        </v-card-subtitle>
      </div>
      <div class="text-center px-4">
        <v-chip label class="text-overline" :color="trendColor" outlined>
          <span class="font-weight-bold">
            {{ $lang.budget[data.trend] }}
          </span>
        </v-chip>
      </div>
    </v-col>
    <v-col class="py-4"></v-col>
  </v-row>
</template>

<script>
import { budgetTile } from '@/mixins/budgetTile'

export default {
  name: 'NumberOnly',

  mixins: [budgetTile],
}
</script>
