<template>
  <v-row align="center" justify="center" class="fill-height">
    <v-expand-transition>
      <v-col cols="12" class="pa-4" v-show="!loading">
        <div class="text-h6 text-center">
          {{ $lang.dashboard.totalCost }}
        </div>
        <div class="text-h4 text-center font-weight-bold primary--text">
          {{ useCostFormatter(cost) }}
        </div>
        <v-card-subtitle class="text-center py-1">
          {{ useDateFormatter(data.start_date) }}
          {{ $lang.card.to }}
          {{ useDateFormatter(data.end_date) }}
        </v-card-subtitle>
        <div class="text-center font-weight-bold">
          <trend-label :trend="trend" />
        </div>
      </v-col>
    </v-expand-transition>
    <v-expand-transition>
      <v-col cols="12" align-self="start" v-show="loading">
        <v-progress-linear
          indeterminate
          height="10"
          color="primary"
        ></v-progress-linear>
      </v-col>
    </v-expand-transition>
    <v-col class="py-4"></v-col>
  </v-row>
</template>

<script setup>
import { ref, watch, defineProps, toRefs, onMounted } from 'vue'

import { useCostFormatter } from '@/composables/cost'
import { useDateFormatter } from '@/composables/date'

import TrendLabel from '@/components/shared/TrendLabel'

import PipelineService from '@/services/pipeline'
const service = new PipelineService()

const props = defineProps({
  data: { type: Object, default: () => ({}) },
  settings: { type: Object, default: () => ({}) },
})

const { data, settings } = toRefs(props)

const cost = ref(0)
const trend = ref(0)
const loading = ref(false)

onMounted(async () => getTotalCost())

async function getTotalCost() {
  loading.value = true
  const scope = Array.isArray(data.value.data_scope)
    ? data.value.data_scope[0].blocks
    : data.value.data_scope.blocks
  const response = await service.aggregate('home/sum', {
    from: data.value.start_date,
    to: data.value.end_date,
    scope,
  })

  cost.value = response
    .map((d) => parseFloat(d.cost))
    .reduce((total, amount) => total + amount, 0)

  trend.value = response.pop().trend

  loading.value = false
}

watch(data, async () => await getTotalCost(), { deep: true })
</script>
