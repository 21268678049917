<template>
  <v-row align="center" justify="center" class="fill-height">
    <v-col cols="12">
      <v-data-table
        height="25vh"
        :items="items"
        :headers="headers"
        :loading="loading"
        dense
        hide-default-footer
      >
        <template #loading>
          <v-row class="fill-height" align="center" justify="center">
            <v-col align-self="center">
              {{ $lang.tags.loadingText }}
            </v-col>
          </v-row>
        </template>
        <template #[`item.value`]="{ item }">
          <span class="font-weight-bold">
            {{ item.value }}
          </span>
        </template>
        <template #[`item.cost`]="{ item }">
          {{ useCostFormatter(item.cost) }}
        </template>
      </v-data-table>
    </v-col>
    <v-col class="py-4"></v-col>
  </v-row>
</template>

<script setup>
import {
  ref,
  computed,
  getCurrentInstance,
  defineProps,
  toRefs,
  onMounted,
  watch,
} from 'vue'

import { useCostFormatter } from '@/composables/cost'

import PipelineService from '@/services/pipeline'
const service = new PipelineService()

const page = ref(1)
const items = ref([])
const loading = ref(true)

const instance = getCurrentInstance().proxy
const $lang = computed(() => instance.$lang)

const props = defineProps({
  data: { type: Object, default: () => ({}) },
  settings: { type: Object, default: () => ({}) },
})

const { data } = toRefs(props)

const headers = computed(() => [
  {
    text: $lang.value.explorer[data.value.explorer.group_by],
    value: 'value',
  },
  { text: $lang.value.explorer.cost, value: 'cost', align: 'right' },
])

onMounted(async () => await fetchTableData())

async function fetchTableData() {
  loading.value = true
  const scope = Array.isArray(data.value.data_scope)
    ? data.value.data_scope[0].blocks
    : data.value.data_scope.blocks
  const payload = {
    from: data.value.start_date,
    to: data.value.end_date,
    group: data.value.explorer.group_by,
    scope,
    offset: (page.value - 1) * 10,
  }

  items.value = await service.aggregate('explorer/group_by_filter', payload)
  loading.value = false
}

watch(data, async () => await fetchTableData(), { deep: true })
</script>
