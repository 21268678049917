<template>
  <v-row align="center" justify="center" class="fill-height">
    <v-col cols="12">
      <div class="text-center pt-5">
        <v-icon large :color="trendColor">{{ `mdi-${trendIcon}` }}</v-icon>
      </div>
      <div class="text-center">
        <v-card-subtitle>
          {{ differenceLabel }}
        </v-card-subtitle>
      </div>
      <div :class="`${trendColor}--text text-h4 text-center font-weight-bold`">
        {{ useCostFormatter(difference) }}
      </div>
      <div class="text-center">
        <v-card-subtitle>
          {{ differenceDescription }}
          {{ useDateFormatter(endDate) }}
        </v-card-subtitle>
      </div>
      <div class="text-center">
        <v-chip label class="text-overline" :color="trendColor" outlined>
          <span class="font-weight-bold">
            {{ $lang.budget[data.trend] }}
          </span>
        </v-chip>
      </div>
    </v-col>
    <v-col class="py-4"></v-col>
  </v-row>
</template>

<script>
import { budgetTile } from '@/mixins/budgetTile'

import { useCostFormatter } from '@/composables/cost'
import { useDateFormatter } from '@/composables/date'

export default {
  name: 'BudgetDifferenceTrend',

  data() {
    return {
      useCostFormatter: useCostFormatter,
      useDateFormatter: useDateFormatter,
    }
  },

  mixins: [budgetTile],

  computed: {
    differenceLabel() {
      return this.$lang.budget[
        this.difference <= 0 ? 'negativeDifference' : 'positiveDifference'
      ]
    },
    differenceDescription() {
      return this.$lang.budget[
        this.difference <= 0
          ? 'negativeDifferenceDescription'
          : 'positiveDifferenceDescription'
      ]
    },
    endDate() {
      return this.periods[this.periods.length - 1].end_date
    },
  },
}
</script>
