<template>
  <div>
    <slot name="actions">
      <v-card-title
        class="py-3 rounded-l"
        :style="backgroundHovered"
        v-if="!hideActions"
      >
        <v-icon :color="tile.color" style="opacity: 100%">mdi-drag</v-icon>
        <v-spacer />
        <v-btn disabled icon color="transparent" depressed></v-btn>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              icon
              text
              v-on="on"
              :color="tile.color"
              v-show="$store.getters.hasPermission('dashboard.update') && hover"
              :loading="loading"
              @click="detachTile"
            >
              <v-icon v-if="tile.id">mdi-close</v-icon>
            </v-btn>
          </template>
          {{ $lang.button.detachTile }}
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              v-on="on"
              icon
              text
              :color="tile.color"
              v-show="isBudget && hover"
              @click.stop="to"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          {{ $lang.button.seeBudget }}
        </v-tooltip>
      </v-card-title>
      <v-card-subtitle
        v-if="tile.color"
        class="pb-1 mt-0"
        :style="`background: ${tile.color}`"
      ></v-card-subtitle>
    </slot>
    <slot name="title">
      <v-card-title class="subtitle-1">
        <span class="text-truncate">
          {{ tile.name }}
        </span>
      </v-card-title>
    </slot>
    <slot name="description">
      <v-card-subtitle class="text-left">
        <span v-show="timeRange">
          <i>{{ $lang.dashboard.periodicity[timeRange] }}</i>
        </span>
      </v-card-subtitle>
    </slot>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  defineEmits,
  defineProps,
  toRefs,
  getCurrentInstance,
} from 'vue'

import { useRouter } from 'vue-router/composables'
import { useHexToRgb } from '@/composables/text'

const router = useRouter()
const instance = getCurrentInstance().proxy
const $lang = computed(() => instance.$lang)
const emit = defineEmits(['detach-tile'])
const props = defineProps({
  tile: { type: Object, default: () => ({}) },
  hideActions: { type: Boolean, default: false },
  hover: { type: Boolean, default: true },
})

const { tile, hover } = toRefs(props)

const loading = ref(false)
async function detachTile() {
  loading.value = true
  await emit('detach-tile', tile.value.id, () => (loading.value = false))
}

const contentType = computed(() => JSON.parse(tile.value.settings).contentType)
const timeRange = computed(() => tile.value.tile_data.time_range)
const isBudget = computed(() => tile.value.tile_data.type === 'budget')

async function to() {
  const { type, id } = tile.value.tile_data

  if (type === 'budget') await router.push(`/budget/${id}`)
}

const backgroundHovered = computed(() => {
  const rgb = useHexToRgb(tile.value.color)
  return hover.value ? `background: rgb(${rgb[0]},${rgb[1]},${rgb[2]},0.2)` : ''
})
</script>
