<template>
  <v-row>
    <v-col cols="12">
      <form-step
        :title="$lang.dashboard.selectBudget"
        :description="$lang.dashboard.selectBudgetDescription"
        number="1"
      >
        <template #content>
          <v-select
            v-model="budget"
            :placeholder="$lang.dashboard.selectBudget"
            :items="budgets"
            item-text="name"
            item-key="id"
            outlined
            dense
            hide-details
            return-object
            @change="setTileData"
          ></v-select>
        </template>
      </form-step>
      <form-step
        :title="$lang.dashboard.selectDataToDisplay"
        :description="$lang.dashboard.selectDataToDisplayDescription"
        number="2"
      >
        <template #content>
          <v-select
            :placeholder="$lang.dashboard.selectBudget"
            :items="budgetsTiles"
            v-model="settings.contentType"
            item-key="type"
            item-text="type"
            outlined
            dense
            hide-details
            @change="setContentType"
            return-object
          >
            <template #item="{ item }">
              {{ $lang.contentTypes[item.type] }}
            </template>
            <template #selection="{ item }">
              {{ $lang.contentTypes[item.type] }}
            </template>
          </v-select>
        </template>
      </form-step>
      <form-step
        :title="$lang.dashboard.selectPeriodicity"
        :description="$lang.dashboard.selectPeriodicityDescription"
        number="3"
      >
        <template #content>
          <v-select
            :placeholder="$lang.dashboard.selectPeriodicity"
            :items="budgetPeriods"
            outlined
            dense
            hide-details
            @change="setPeriod"
          >
            <template #item="{ item }">
              {{ $lang.dashboard[`${item}BudgetPeriod`] }}
            </template>
            <template #selection="{ item }">
              {{ $lang.dashboard[`${item}BudgetPeriod`] }}
            </template>
          </v-select>
        </template>
      </form-step>
    </v-col>
  </v-row>
</template>
<script setup>
import { onMounted, ref, toRefs } from 'vue'
import BudgetService from '@/services/budget'
import { TileModel } from '@/components/tile/TileModel'

const props = defineProps({
  tile: {
    type: Object,
    required: true,
  },
})
const { tile } = toRefs(props)
const emit = defineEmits(['edit-type'])
const budget = ref({})
const budgets = ref([])
const budgetPeriods = ref(['current', 'previous', 'total'])
const settings = ref({
  contentType: '',
  period: 'current',
})

const budgetsTiles = ref([
  {
    type: 'BudgetConsumptionTrend',
    with: [],
    size: 'small',
  },
  {
    type: 'BudgetPercentageTrend',
    with: [],
    size: 'small',
  },
  {
    type: 'BudgetDonutTrend',
    with: [],
    size: 'small',
  },
  {
    type: 'BudgetDifferenceTrend',
    with: [],
    size: 'small',
  },
])
onMounted(async () => {
  await fetchBudgets()
})

async function fetchBudgets() {
  const service = new BudgetService()
  const { data } = await service.listRunning()
  budgets.value = data
}
function setTileData(budget) {
  tile.value.name = budget.name
  tile.value.tile_data = budget
}
function setContentType({ type, size }) {
  tile.value.settings = {
    ...tile.value.settings,
    contentType: type,
  }
  tile.value.size = size
}
function setPeriod(period) {
  tile.value.settings = {
    ...tile.value.settings,
    period,
  }
}
</script>
