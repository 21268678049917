<template>
  <component
    :is="contentType"
    :tile="tile"
    :settings="settings"
    :hover="hover"
    v-on="$listeners"
  ></component>
</template>

<script setup>
import { defineProps, toRefs, computed, markRaw } from 'vue'

import ExplorerDataTableHeader from '@/components/tile/headers/ExplorerDataTableHeader'
import ExplorerTimeChartHeader from '@/components/tile/headers/ExplorerTimeChartHeader'
import ExplorerTotalHeader from '@/components/tile/headers/ExplorerTotalHeader'
import ExplorerTagCoveringSummaryHeader from '@/components/tile/headers/ExplorerTagCoveringSummaryHeader'
import ExplorerDistributionChartHeader from '@/components/tile/headers/ExplorerDistributionChartHeader'
import BudgetPercentageTrendHeader from '@/components/tile/headers/BudgetPercentageTrendHeader'
import BudgetDifferenceTrendHeader from '@/components/tile/headers/BudgetDifferenceTrendHeader'
import BudgetConsumptionTrendHeader from '@/components/tile/headers/BudgetConsumptionTrendHeader'
import BudgetDonutTrendHeader from '@/components/tile/headers/BudgetDonutTrendHeader'
import SectionTitleHeader from '@/components/tile/headers/SectionTitleHeader'

const props = defineProps({
  tile: { type: Object, default: () => ({}) },
  settings: { type: Object, default: () => ({}) },
  hover: { type: Boolean, default: true },
})

const { settings } = toRefs(props)

const components = {
  ExplorerDataTableHeader,
  ExplorerTimeChartHeader,
  ExplorerTotalHeader,
  ExplorerTagCoveringSummaryHeader,
  ExplorerDistributionChartHeader,
  BudgetPercentageTrendHeader,
  BudgetDifferenceTrendHeader,
  BudgetConsumptionTrendHeader,
  BudgetDonutTrendHeader,
  SectionTitleHeader,
}

const contentType = computed(() => {
  if (settings.value.contentType === 'ExplorerMultiscopeChart') {
    return markRaw(ExplorerTimeChartHeader)
  }
  return markRaw(components[`${settings.value.contentType}Header`])
})
</script>
