<template>
  <v-card class="pa-0 overflow-x-hidden">
    <v-row justify="center" class="ma-0 pa-0">
      <v-col cols="12">
        <v-toolbar flat>
          <v-toolbar-title>
            {{ $lang.button.createRecommendationGroup }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon text @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-col>
      <v-col
        cols="10"
        align-self="start"
        style="max-height: 70vh"
        class="overflow-auto"
      >
        <form-step
          :title="$lang.recommendations.name"
          :description="$lang.recommendations.groupNameDescription"
          number="1"
        >
          <template #content>
            <v-text-field
              outlined
              rounded
              dense
              hide-details
              :placeholder="$lang.recommendations.name"
              v-model="name"
              style="max-width: 400px"
            ></v-text-field>
          </template>
        </form-step>
        <form-step
          :title="$lang.recommendations.description"
          :description="$lang.recommendations.description"
          number="2"
        >
          <template #content>
            <v-textarea
              outlined
              rounded
              dense
              hide-details
              :placeholder="$lang.recommendations.description"
              v-model="description"
              style="max-width: 600px"
            ></v-textarea>
          </template>
        </form-step>
        <form-step
          :title="$lang.recommendations.chose"
          :description="$lang.recommendations.choseDescription"
          number="3"
        >
          <template #content>
            <recommendation-table
              hide-header
              disable-click-row
              show-select
              dense
              hide-actions
              :recommendations="recommendations"
              :loading="recommendationsLoading"
            >
              <template #select="{ item }">
                <v-checkbox
                  dense
                  hide-details
                  v-model="selectedRecommendations"
                  :value="item.id"
                ></v-checkbox>
              </template>
            </recommendation-table>
          </template>
        </form-step>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="10">
        <form-step
          :title="$lang.recommendations.saveGroup"
          :description="$lang.recommendations.saveGroupDescription"
          number="0"
        >
          <template #content>
            <v-btn
              block
              depressed
              color="green"
              tile
              :loading="creationLoading"
              :disabled="!canCreateGroup"
              @click="createRecommendationGroup"
              class="align-self-center text-overline mx-2 white--text"
            >
              <span class="text-overline">{{ $lang.button.save }}</span>
            </v-btn>
          </template>
        </form-step>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import RecommendationTable from '@/components/recommendations/RecommendationTable'
import RecommendationsMixin from '@/mixins/recommendations'
export default {
  name: 'RecommendationGroupEditor',
  components: {
    RecommendationTable,
  },

  mixins: [RecommendationsMixin],

  data() {
    return {
      name: null,
      description: null,
      creationLoading: false,
      selectedRecommendations: [],
    }
  },

  computed: {
    canCreateGroup() {
      return this.selectedRecommendations.length > 1 && this.name
    },
  },

  methods: {
    async createRecommendationGroup() {
      this.creationLoading = true
      const newGroup = await this.recommendationGroupService.create({
        name: this.name,
        description: this.description,
        recommendations: this.selectedRecommendations,
      })
      this.$emit('close', newGroup)
      this.creationLoading = false
    },
  },
  async beforeMount() {
    await this.fetchRecommendations()
  },
}
</script>
