<template>
  <v-container fluid class="pa-0">
    <v-text-field
      outlined
      dense
      hide-details
      :placeholder="$lang.dashboard.searchDashboards"
      v-model="search"
      class="pb-4"
    ></v-text-field>
    <v-card outlined flat height="100%">
      <v-data-table
        :items="dashboards"
        :headers="dashboardsHeaders"
        :search="search"
        v-model="dashboardSelection"
        show-select
        height="100%"
        dense
      >
        <template #[`item.description`]="{ item }">
          {{ item.description || $lang.dashboard.noDashboardDescription }}
        </template>
        <template #no-data>
          <v-sheet height="1OO%">
            <v-row class="fill-height" align="center" justify="center">
              <v-col>
                <div class="py-4">
                  {{ $lang.dashboard.noDashboardFound }}
                </div>
                <v-btn depressed color="primary" dark>
                  <span class="text-overline">
                    {{ $lang.dashboard.createNewDashboard }}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-sheet>
        </template>
        <template #no-results>
          <v-sheet height="300">
            <empty-space
              :title="$lang.dashboard.emptyDashboardTitle"
              :description="$lang.dashboard.emptyDashboardDescription"
              :action-title="$lang.dashboard.emptyDashboardActionTitle"
            >
              <template #image>
                <v-img
                  max-height="300"
                  src="/image/dashboard.png"
                  contain
                ></v-img>
              </template>
            </empty-space>
          </v-sheet>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script setup>
import {
  ref,
  onMounted,
  watch,
  defineEmits,
  getCurrentInstance,
  computed,
} from 'vue'

import EmptySpace from '@/views/EmptySpace'

import DashboardService from '@/services/dashboard'
const service = new DashboardService()

const instance = getCurrentInstance().proxy
const $lang = computed(() => instance.$lang)

const emit = defineEmits(['set-dashboards'])

const search = ref('')
const dashboardSelection = ref([])
const dashboards = ref([])
const dashboardsHeaders = ref([
  { text: $lang.value.dashboard.dashboardName, value: 'name' },
  {
    text: $lang.value.dashboard.dashboardDescription,
    value: 'description',
  },
])

onMounted(async () => await fetchDashboards())

async function fetchDashboards() {
  const { data } = await service.listDashboards()
  dashboards.value = data
}

watch(dashboardSelection, (v) => emit('set-dashboards', v))
</script>
