<template>
  <v-tabs color="primary" centered>
    <v-tab
      v-for="(type, i) in tileTypes"
      :key="i"
      @click="emit('set-tile-type', type)"
    >
      {{ $lang.contentTypes[type] }}
    </v-tab>
  </v-tabs>
</template>

<script setup>
import { defineEmits } from 'vue'

const emit = defineEmits(['set-tile-type'])

const tileTypes = ['BudgetTile', 'ExplorerTile', 'SectionTile']
</script>
