import moment from 'moment/moment'

const timeRangeMap = {
  this_month: {
    start_date: moment().startOf('month'),
    end_date: moment(),
  },
  last_month: {
    start_date: moment().subtract(1, 'month').startOf('month'),
    end_date: moment().subtract(1, 'month').endOf('month'),
  },
  last_7_days: {
    start_date: moment().subtract(6, 'days'),
    end_date: moment(),
  },
  last_30_days: {
    start_date: moment().subtract(30, 'days'),
    end_date: moment(),
  },
  last_6_months: {
    start_date: moment().subtract(6, 'months'),
    end_date: moment(),
  },
  this_year: {
    start_date: moment().startOf('year'),
    end_date: moment(),
  },
  last_year: {
    start_date: moment().subtract(1, 'year').startOf('year'),
    end_date: moment().subtract(1, 'year').endOf('year'),
  },
}

export function setTimeRange(payload) {
  const format = 'YYYY-MM-DD'
  const start_date = timeRangeMap[payload].start_date.format(format)
  const end_date = timeRangeMap[payload].end_date.format(format)
  return { start_date, end_date }
}
