export const session = {
  methods: {
    /**
     * Start user session
     *
     * @param { string } token
     * @param { string } user
     * @param { string } company
     *
     * TODO: This method is ugly.
     */
    async startSession(token, user, company) {
      // clear localStorage
      const langSave = localStorage.getItem('lang') || 'fr'
      const helpSave = localStorage.getItem('help') || 'fr'

      localStorage.clear()

      localStorage.setItem('lang', langSave)
      localStorage.setItem('help', helpSave)
      localStorage.setItem('darkMode', this.$vuetify.theme.dark)
      localStorage.setItem('api_token', token)

      // set User and Company in store
      let UserStore

      try {
        this.userService.refreshToken()
        const result = await this.userService.getInfos(user)

        UserStore = result.data

        if (result.code === 200) {
          Object.keys(UserStore).forEach((k) => {
            this.$store.commit('setUserInfo', { info: k, value: UserStore[k] })
          })
        }
      } catch (error) {
        throw new Error('Token error')
      }

      if (null !== company) {
        try {
          const result = await this.userService.getCompany(company)
          this.$store.commit('setCompanyInfo', result.data)
        } catch (error) {
          throw error
        }
      }

      // set new local storage
      localStorage.setItem('darkMode', this.$vuetify.theme.dark)
      localStorage.setItem('offer', UserStore.offer)

      // end session
      this.loading = false
      this.success = true

      // routing
      this.$router.push({ path: this.$route.query.redirect || '/dashboard' })
    },
  },
}
