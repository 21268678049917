import store from '@/store'

export async function useCopy(data, msg) {
  await navigator.clipboard.writeText(data)
  await store.dispatch('pushMessage', {
    message: msg, // TODO: i18n
    color: 'info',
    icon: 'mdi-content-copy',
  })
}

export function useHexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  if (result && result.length > 3) {
    return [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16),
    ]
  }
  return [255, 255, 255]
}
