import Format from '@/format'

export const chartOptions = {
  exporting: {
    enabled: false,
    buttons: {
      contextButton: {
        menuItems: ['printChart', 'downloadPNG', 'downloadPDF', 'downloadSVG'],
      },
    },
  },

  chart: {
    type: 'column',
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    backgroundColor: 'transparent',
    zoomType: 'x',
  },
  title: {
    text: '',
  },
  xAxis: {
    type: '',
    title: { text: '' },
    labels: {},
  },
  yAxis: {
    min: 0,
    title: { text: '' },
    minorTickInterval: 1,
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      showInLegend: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        style: {
          color: '#2183c6',
          textOutline: 'none',
          fontWeight: 'light',
          fontSize: '16px',
          textOverflow: 'ellipsis',
        },
      },
      series: {
        borderColor: 'transparent',
      },
      events: {},
    },
  },
  colors: [
    '#0070d9',
    '#97d903',
    '#ffe300',
    '#ea4600',
    '#23d0d2',
    '#ff9947',
    '#6d4912',
    '#896bc7',
    '#712f2a',
    '#09690d',
    '#004113',
    '#ffb500',
    '#007677',
    '#244549',
    '#43d7ff',
    '#213863',
    '#d8a7ff',
    '#3c3059',
  ],
  credits: {
    enabled: false,
  },
  legend: {
    enabled: true,
    itemStyle: {
      color: '#2183c6',
      textOutline: 'none',
      fontWeight: 'light',
      fontSize: '12px',
      textOverflow: 'ellipsis',
    },
    labelFormatter: function () {
      return Format.resourceId(this.name)
    },
  },
  scrollbar: {
    enabled: true,
  },
  series: [],
}
