<template>
  <v-row align="center" justify="center" class="fill-height">
    <v-col cols="12">
      <div class="text-center pt-5">
        <v-icon large :color="trendColor">{{ `mdi-${trendIcon}` }}</v-icon>
      </div>
      <div class="text-center">
        <v-card-subtitle>
          {{ $lang.budget.consumption }}
        </v-card-subtitle>
      </div>
      <div class="text-h4 text-center font-weight-bold">
        <v-progress-linear
          height="70"
          :value="percentage"
          :color="trendColor"
          class="white--text"
        >
          {{ percentage | trend }}
        </v-progress-linear>
      </div>
      <div class="text-center">
        <v-card-subtitle>
          {{ data.start_date | date }}
          {{ $lang.card.to }}
          {{ data.end_date | date }}
        </v-card-subtitle>
      </div>
      <div class="text-center">
        <v-chip label class="text-overline" :color="trendColor" outlined>
          <span class="font-weight-bold">
            {{ $lang.budget[data.trend] }}
          </span>
        </v-chip>
      </div>
    </v-col>
    <v-col class="py-4"></v-col>
  </v-row>
</template>

<script setup>
import { toRefs, computed, defineProps } from 'vue'

import moment from 'moment'

const props = defineProps({
  data: { type: Object, default: () => ({}) },
  settings: { type: Object, default: () => ({}) },
})

const { data, settings } = toRefs(props)

const periods = computed(() => {
  return data.value.periods.filter((p) => {
    if (settings.value.period === 'current') {
      const now = moment()
      return now.isBetween(moment(p.start_date), moment(p.end_date))
    }
    if (settings.value.period === 'previous') {
      const now = moment().subtract(1, 'month')
      return now.isBetween(moment(p.start_date), moment(p.end_date))
    }
    return true
  })
})

const endDate = computed(() => periods.value[periods.value.length - 1].end_date)
const startDate = computed(() => periods.value[0].start_date)

const percentage = computed(() => (consumption.value / allocated.value) * 100)

const difference = computed(() =>
  periods.value.reduce(
    (acc, cur) =>
      (acc +=
        cur.realized_cost -
        (cur.reforecast ? cur.reforecast : cur.allocated_cost)),
    0,
  ),
)

const consumption = computed(() =>
  periods.value.reduce((acc, cur) => (acc += cur.realized_cost), 0),
)

const allocated = computed(() =>
  periods.value.reduce(
    (acc, cur) => (acc += cur.reforecast ? cur.reforecast : cur.allocated_cost),
    0,
  ),
)

const trendColor = computed(() =>
  data.value.trend === 'OK'
    ? 'green'
    : data.value.trend === 'WATCH'
    ? 'orange'
    : 'red',
)

const trendIcon = computed(() =>
  data.value.trend === 'OK'
    ? 'checkbox-marked-circle-outline'
    : data.value.trend === 'WATCH'
    ? 'alert-circle-outline'
    : 'close-circle-outline',
)
</script>
