<template>
  <component
    :is="contentType"
    :data="data"
    :settings="settings"
    :preview="preview"
    v-on="$listeners"
  ></component>
</template>

<script setup>
import { defineProps, markRaw, computed, toRefs, onMounted } from 'vue'

import ExplorerDataTable from '@/components/tile/contents/ExplorerDataTable'
import ExplorerTimeChart from '@/components/tile/contents/ExplorerTimeChart'
import ExplorerTotal from '@/components/tile/contents/ExplorerTotal'
import ExplorerTagCoveringSummary from '@/components/tile/contents/ExplorerTagCoveringSummary'
import ExplorerDistributionChart from '@/components/tile/contents/ExplorerDistributionChart'
import BudgetPercentageTrend from '@/components/tile/contents/BudgetPercentageTrend'
import BudgetDifferenceTrend from '@/components/tile/contents/BudgetDifferenceTrend'
import BudgetConsumptionTrend from '@/components/tile/contents/BudgetConsumptionTrend'
import BudgetDonutTrend from '@/components/tile/contents/BudgetDonutTrend'
import ExplorerMultiscopeChart from '@/components/tile/contents/ExplorerMultiscopeChart'
import SectionTitle from '@/components/tile/contents/SectionTitle'

const props = defineProps({
  data: { type: Object, default: () => ({}) },
  settings: { type: Object, default: () => ({}) },
  preview: { type: Boolean, default: false },
})

const components = {
  ExplorerDataTable,
  ExplorerTimeChart,
  ExplorerMultiscopeChart,
  ExplorerTotal,
  ExplorerTagCoveringSummary,
  ExplorerDistributionChart,
  BudgetPercentageTrend,
  BudgetDifferenceTrend,
  BudgetConsumptionTrend,
  BudgetDonutTrend,
  SectionTitle,
}
const { settings } = toRefs(props)

const contentType = computed(() => {
  return markRaw(components[settings.value.contentType])
})
</script>
