<template>
  <v-hover v-slot="{ hover }">
    <v-card outlined hover rounded height="100%" class="rounded-l">
      <tile-header-wrapper
        :preview="preview"
        :tile="tile"
        :settings="settings"
        :hover="hover"
        v-on="$listeners"
      ></tile-header-wrapper>
      <div :style="height">
        <tile-content-wrapper
          :preview="preview"
          :data="tile.tile_data"
          :settings="settings"
          v-on="$listeners"
        ></tile-content-wrapper>
      </div>
    </v-card>
  </v-hover>
</template>

<script setup>
import { defineProps, computed, toRefs } from 'vue'

import TileHeaderWrapper from '@/components/tile/TileHeaderWrapper'
import TileContentWrapper from '@/components/tile/TileContentWrapper'

const props = defineProps({
  tile: { type: Object, default: () => ({}) },
  preview: { type: Boolean, default: false },
})

const { tile } = toRefs(props)

const settings = computed(() => JSON.parse(tile.value.settings))
const height = computed(() => {
  if (settings.value.contentType !== 'SectionTitle') {
    return 'height: 35vh'
  }
  return ''
})
</script>
