import HttpClient from '@/services/base'

export default class Budget extends HttpClient {
  constructor() {
    super()
  }

  async list() {
    return await this.request({
      url: '/budgets',
      method: 'GET',
      data: null,
    })
  }

  async listRunning() {
    return await this.request({
      url: '/budgets/running',
      method: 'GET',
      data: null,
    })
  }

  async get(id) {
    return await this.request({
      url: `/budget/${id}`,
      method: 'GET',
      data: null,
    })
  }

  async create(params) {
    return await this.request({
      url: '/budget',
      method: 'POST',
      data: params,
    })
  }

  async delete(id) {
    return await this.request({
      url: `/budget/${id}`,
      method: 'DELETE',
      data: null,
    })
  }

  async update(id, params) {
    return await this.request({
      url: `/budget/${id}`,
      method: 'PUT',
      data: params,
    })
  }

  async attachDimension(budgetId, dimensionId) {
    return await this.request({
      url: `budget/${budgetId}/dimension/${dimensionId}`,
      method: 'POST',
      data: null,
    })
  }

  async updatePeriods(id, params) {
    return await this.request({
      url: `/budget/${id}/periods`,
      method: 'PATCH',
      data: params,
    })
  }
  async updateResponsible(budgetId, responsibleId) {
    return await this.request({
      url: `/budget/${budgetId}/responsible/${responsibleId}`,
      method: 'PATCH',
      data: null,
    })
  }
  async predict(list, future) {
    return await this.request({
      url: `/tool/predict`,
      method: 'GET',
      params: { list, future },
    })
  }
  async exportBudgets() {
    return await this.request({
      url: `/budgets/export-budgets`,
      method: 'GET',
      data: null,
      responseType: 'blob',
    })
  }
  async exportPeriods(start_date, end_date) {
    return await this.request({
      url: `/budgets/export-budgets-periods`,
      method: 'GET',
      params: { start_date, end_date },
      responseType: 'blob',
    })
  }
}
