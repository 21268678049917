import moment from 'moment'
import { chartOptions } from '@/components/chartOptions'
import { useCostFormatter } from '@/composables/cost'

export const tileChart = {
  data() {
    return {
      customChartOptions: {
        chart: {
          type: this.settings.timeChartType || 'column',
          backgroundColor: 'transparent',
          height: '300px',
        },
        legend: {
          enabled: true,
        },
        plotOptions: {
          areaspline: {
            stacking: 'normal',
            borderWidth: 0,
            fillOpacity: 0.8,
          },
          column: {
            stacking: 'normal',
            borderWidth: 0,
            fillOpacity: 0.7,
          },
          line: {
            borderWidth: 0,
          },
          pie: {
            dataLabels: { enabled: false },
            borderWidth: 0,
            fillOpacity: 0.7,
          },
          treemap: {
            dataLabels: { enabled: true },
            borderWidth: 0,
            fillOpacity: 0.7,
          },
        },
        series: [],
        xAxis: {
          title: {
            text: '',
          },
          categories: [],
          labels: {
            enabled: true,
            formatter: (label) => '',
          },
          plotBands: [],
        },
      },
    }
  },

  props: {
    data: { type: Object, default: () => ({}) },
    settings: { type: Object, default: () => ({}) },
  },

  computed: {
    chartOptions() {
      return { ...chartOptions, ...this.customChartOptions }
    },
  },

  methods: {
    getDateFormat() {
      return ['quarter', 'month'].includes(this.data.explorer.granularity)
        ? 'YYYY-MM'
        : this.data.explorer.granularity === 'year'
        ? 'YYYY'
        : 'YYYY-MM-DD'
    },
    setTimeChartCategories() {
      this.customChartOptions.xAxis.categories = []
      let start = moment(this.data.start_date).startOf(
        this.data.explorer.granularity,
      )
      const end = moment(this.data.end_date).add(1, 'day')
      const fmt = this.getDateFormat()
      while (start.isBefore(end)) {
        this.customChartOptions.xAxis.categories.push(start.format(fmt))
        start.add(1, this.data.explorer.granularity)
      }
    },
    setTimeChartSeries(data) {
      this.customChartOptions.series = data.map((s) => ({
        ...s,
        data: s.data.map(({ date, cost }) => [
          this.customChartOptions.xAxis.categories.findIndex((c) => c === date),
          cost,
        ]),
      }))
    },
    setTimeChartInterval() {
      this.customChartOptions.xAxis.tickInterval =
        this.customChartOptions.xAxis.categories.length > 12 ? 5 : 1
    },
    setTimeChartType() {
      this.customChartOptions.chart.type = this.settings.timeChartType
    },
    setTimeChartOptions(data) {
      this.setTimeChartCategories()
      this.setTimeChartSeries(data)
      this.setTimeChartInterval()
    },
    setDistributionChartData(keys, data) {
      this.customChartOptions.series = [
        {
          name: this.$lang.dashboard.tagCoveringLabel,
          data: keys.map((key) => ({
            name: this.$lang.dashboard[key],
            y: data[key],
          })),
        },
      ]
    },
  },
  watch: {
    settings: {
      handler() {
        this.setTimeChartType()
      },
      deep: true,
    },
  },
}
