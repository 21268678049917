<template>
  <v-row>
    <v-col cols="12">
      <form-step
        :title="$lang.dashboard.selectFilter"
        :description="$lang.dashboard.selectFilterDescription"
        number="1"
      >
        <template #content>
          <transition-group name="list" tag="p" style="overflow-x: hidden">
            <v-card
              v-for="filter in selectedFilters"
              style="width: 100%; height: 38px"
              rounded
              flat
              outlined
              class="my-2 d-flex align-center justify-space-between list-item"
              :key="filter.id"
            >
              <v-card-text>
                {{ filter.name }}
              </v-card-text>
              <v-spacer />
              <v-icon color="red" @click="remove(filter)" class="mr-2">
                mdi-close
              </v-icon>
            </v-card>
          </transition-group>
          <v-expand-transition>
            <v-autocomplete
              v-model="selectedFilters"
              v-if="selectedFilters.length < 4"
              :items="filtersToDisplay"
              :label="$lang.dashboard.selectFilter"
              item-text="name"
              item-value="id"
              outlined
              multiple
              return-object
              dense
            >
              <template v-slot:selection=""></template>
              <template v-slot:item="{ item }">
                <div style="font-size: 14px">{{ item.name }}</div>
              </template>
            </v-autocomplete>
          </v-expand-transition>
          <v-expand-transition>
            <v-card v-show="selectedFilters.length >= 4" flat>
              <v-card-text class="font-mangueira">
                {{ $lang.dashboard.maxFilters }}
              </v-card-text>
            </v-card>
          </v-expand-transition>
        </template>
      </form-step>
      <form-step
        :title="$lang.dashboard.selectPeriodicity"
        :description="$lang.dashboard.selectPeriodicityDescription"
        number="2"
      >
        <template #content>
          <v-select
            outlined
            :placeholder="$lang.dashboard.selectPeriodicity"
            :items="explorerPeriodicityList"
            hide-details
            dense
            @change="timeRange"
          >
            <template #item="{ item }">
              {{ $lang.dashboard.periodicity[`${item}`] }}
            </template>
            <template #selection="{ item }">
              {{ $lang.dashboard.periodicity[`${item}`] }}
            </template>
          </v-select>
        </template>
      </form-step>
      <form-step
        :title="$lang.dashboard.selectDataToDisplay"
        :description="$lang.dashboard.selectDataToDisplayDescription"
        number="3"
      >
        <template #content>
          <v-select
            :placeholder="$lang.dashboard.selectDataToDisplay"
            :items="explorerTilesToDisplay"
            v-model="settings.contentType"
            item-key="type"
            outlined
            dense
            hide-details
            @change="setContentType"
            return-object
          >
            <template #item="{ item }">
              {{ $lang.contentTypes[item.type] }}
            </template>
            <template #selection="{ item }">
              {{ $lang.contentTypes[item.type] }}
            </template>
          </v-select>
          <v-expand-transition>
            <v-select
              v-model="groupBy"
              v-show="settings.contentType && hasGroupBy"
              :placeholder="$lang.dashboard.selectGroupBy"
              :items="groupByList"
              class="my-2"
              outlined
              dense
              hide-details
              return-object
              @change="setGroupBy"
            >
              <template #item="{ item }">
                {{ $lang.dashboard.groupBy[item] }}
              </template>
              <template #selection="{ item }">
                {{ $lang.dashboard.groupBy[item] }}
              </template>
            </v-select>
          </v-expand-transition>
          <v-expand-transition>
            <v-select
              v-model="granularity"
              v-show="groupBy && hasGranularity && isMultiScope"
              :placeholder="$lang.dashboard.selectGranularity"
              :items="granularityList"
              class="my-2"
              outlined
              dense
              hide-details
              return-object
              @change="setGranularity"
            >
              <template #selection="{ item }">
                {{ $lang.dashboard.granularity[item] }}
              </template>
            </v-select>
          </v-expand-transition>
          <v-expand-transition>
            <v-tabs
              fixed-tabs
              v-show="hasTimeChartType"
              v-model="settings.timeChartType"
              hide-slider
              icons-and-text
            >
              <v-tab
                v-for="type in timeChartTypes"
                :key="type.name"
                @click="setTimeChartType(type.value)"
              >
                <div>
                  <v-icon>
                    {{ type.icon }}
                  </v-icon>
                </div>
                <div>{{ $lang.dashboard.chartTypes[type.name] }}</div>
              </v-tab>
            </v-tabs>
          </v-expand-transition>
          <v-expand-transition>
            <v-tabs
              fixed-tabs
              v-show="groupBy && hasDistributionChartType"
              v-model="settings.distributionChartType"
              hide-slider
              icons-and-text
            >
              <v-tab
                v-for="type in distributionChartTypes"
                :key="type.name"
                @click="setDistributionChartType(type.value)"
              >
                <div>
                  <v-icon>
                    {{ type.icon }}
                  </v-icon>
                </div>
                <div>{{ $lang.dashboard.chartTypes[type.name] }}</div>
              </v-tab>
            </v-tabs>
          </v-expand-transition>
        </template>
      </form-step>
    </v-col>
  </v-row>
</template>

<script setup>
import { ref, computed, onMounted, toRefs, watch } from 'vue'
import DataScopeService from '@/services/dataScope'
import { setTimeRange } from '@/composables/tile'
const service = new DataScopeService()
const props = defineProps({
  tile: {
    type: Object,
    required: true,
  },
})
const { tile } = toRefs(props)

onMounted(async () => {
  const data = await service.list()
  if (data && data.length) filters.value = data
  filters.value.map((filter) => {
    return {
      ...filter,
      selected: false,
    }
  })
})

const selectedFilters = ref([])
const filters = ref([])
const granularity = ref(null)
const granularityList = ['day', 'week', 'month', 'quarter', 'year']
const groupBy = ref(null)
const groupByList = [
  'provider',
  'category',
  'product',
  'resource_id',
  'usage_account_id',
  'region',
  'usage_type',
  'tags',
]

const explorerTiles = [
  {
    type: 'ExplorerDataTable',
    with: ['group_by'],
    size: 'medium',
    multiScope: false,
  },
  {
    type: 'ExplorerTotal',
    with: [],
    size: 'small',
    multiScope: false,
  },
  {
    type: 'ExplorerDistributionChart',
    with: ['group_by', 'distribution_chart_type'],
    size: 'small',
    multiScope: false,
  },
  {
    type: 'ExplorerTimeChart',
    with: ['group_by', 'granularity', 'time_chart_type'],
    size: 'large',
    multiScope: false,
  },
  {
    type: 'ExplorerMultiscopeChart',
    with: ['group_by', 'time_chart_type'],
    size: 'large',
    multiScope: true,
  },
]

const explorerPeriodicityList = [
  'this_month',
  'last_month',
  'last_7_days',
  'last_30_days',
  'last_6_months',
  'this_year',
  'last_year',
]

const timeChartTypes = [
  { icon: 'mdi-chart-bar', name: 'columnChart', value: 'column' },
  { icon: 'mdi-chart-line', name: 'lineChart', value: 'line' },
  {
    icon: 'mdi-chart-areaspline',
    name: 'areaChart',
    value: 'areaspline',
  },
]

const distributionChartTypes = [
  { icon: 'mdi-chart-pie', name: 'pieChart', value: 'pie' },
  { icon: 'mdi-chart-tree', name: 'treeChart', value: 'treemap' },
]

const settings = ref({
  contentType: '',
  period: null,
  timeChartType: 'column',
  distributionChartType: 'pie',
})
const filtersToDisplay = computed(() => {
  return filters.value.filter((filter) => !filter.selected)
})

const isMultiScope = computed(() => {
  return selectedFilters.value.length <= 1
})
const explorerTilesToDisplay = computed(() => {
  return selectedFilters.value.length > 1
    ? explorerTiles.filter((tile) => tile.multiScope)
    : explorerTiles.filter((tile) => !tile.multiScope)
})

const hasGranularity = computed(() => hasProperty('granularity'))
const hasGroupBy = computed(() => hasProperty('group_by'))
const hasTimeChartType = computed(() => hasProperty('time_chart_type'))
const hasDistributionChartType = computed(() =>
  hasProperty('distribution_chart_type'),
)
function hasProperty(property) {
  const explorerTile = explorerTiles.find(
    (tile) => tile.type === settings.value.contentType?.type,
  )
  return explorerTile?.with.includes(property) || false
}
function remove(item) {
  const index = selectedFilters.value.indexOf(item)
  if (index >= 0) {
    selectedFilters.value.splice(index, 1)
  }
}
function setContentType({ type, size }) {
  if (type === 'ExplorerTimeChart' && selectedFilters.value.length > 1) {
    tile.value.settings.contentType = 'ExplorerMultiscopeChart'
    tile.value.size = 'large'
    return
  }
  tile.value.settings.contentType = type
  tile.value.size = size
}
function setTimeChartType(type) {
  tile.value.settings = {
    ...tile.value.settings,
    timeChartType: type,
  }
}
function setDistributionChartType(type) {
  tile.value.settings = {
    ...tile.value.settings,
    distributionChartType: type,
  }
}

function timeRange(timeRange) {
  settings.value.period = timeRange
  tile.value.tile_data = {
    ...tile.value.tile_data,
    start_date: setTimeRange(timeRange).start_date,
    end_date: setTimeRange(timeRange).end_date,
    time_range: timeRange,
  }
  tile.value.settings = {
    ...tile.value.settings,
    period: timeRange,
  }
}
function setGroupBy(groupBy) {
  tile.value.tile_data.explorer = {
    ...tile.value.tile_data.explorer,
    group_by: groupBy,
  }
}

function setGranularity(granularity) {
  tile.value.tile_data.explorer = {
    ...tile.value.tile_data.explorer,
    granularity: granularity,
  }
}
watch(
  selectedFilters,
  () => {
    tile.value.settings.contentType = ''
    settings.value.contentType = ''
    tile.value.tile_data.multiScope = selectedFilters.value.length > 1
    if (selectedFilters.value.length <= 1) {
      tile.value.name = selectedFilters.value[0]?.name || ''
    } else {
      tile.value.name =
        selectedFilters.value.map((filter) => filter.name).join('-') || ''
      tile.value.size = 'large'
    }
    tile.value.tile_data = {
      ...tile.value.tile_data,
      data_scope: selectedFilters.value,
    }
  },
  { deep: true },
)
</script>
<style scoped>
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.5s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateX(-80px);
}
.v-list-item--active {
  background-color: #fff;
}
</style>
