import HttpClient from '@/services/base'

export default class DashboardSection extends HttpClient {
  constructor() {
    super()
  }

  async create(data) {
    return await this.request({
      url: '/dashboard-sections/',
      method: 'POST',
      data,
    })
  }

  async list() {
    return await this.request({
      url: '/dashboard-sections/',
      method: 'GET',
    })
  }
}
