<template>
  <v-app>
    <NotificationSnackbar />

    <!-- navbar -->
    <AppBar :title="title" ref="appBar" style="min-width: 360px" />

    <!-- menu -->
    <NavBar />
    <!-- page -->
    <v-main>
      <router-view name="dialog"></router-view>
      <router-view
        @open-recommendation-group-editor="
          openRecommendationGroupEditor($event)
        "
        @open-tile-editor="openTileEditor"
        @open-dashboard-editor="openDashboardEditor"
      />
    </v-main>

    <!-- recommendation group editor -->
    <v-dialog
      fullscreen
      :key="recommendationGroupEditorKey"
      v-model="recommendationGroupEditorDialog"
    >
      <recommendation-group-editor
        @close="closeRecommendationGroupEditor"
      ></recommendation-group-editor>
    </v-dialog>

    <!-- dashboard editor -->
    <v-dialog fullscreen v-model="dashboardEditorDialog">
      <dashboard-editor
        @close="dashboardEditorDialog = false"
      ></dashboard-editor>
    </v-dialog>

    <!-- tile editor -->
    <v-dialog fullscreen v-model="tileEditorDialog">
      <tile-editor :key="tileEditorKey" @close="closeTileEditor"></tile-editor>
    </v-dialog>
  </v-app>
</template>

<script setup>
import { ref, computed, onMounted, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router/composables'
import * as Sentry from '@sentry/vue'

import AppBar from '@/components/layouts/AppBar.vue'
import NavBar from '@/components/layouts/NavBar.vue'
import TileEditor from '@/components/tile/TileEditor'
import DashboardEditor from '@/components/dashboard/NewDashboard'
import RecommendationGroupEditor from '@/components/recommendations/RecommendationGroupEditor'
import NotificationSnackbar from './components/shared/NotificationSnackbar.vue'

import { useLanguage } from '@/composables/locale'

const instance = getCurrentInstance()
const store = computed(() => instance.proxy.$store)
const lang = computed(() => instance.proxy.$lang)
const route = useRoute()

const recommendationGroupEditorDialog = ref(false)
const recommendationGroupEditorKey = ref(0)
const tileEditorDialog = ref(false)
const dashboardEditorDialog = ref(false)
const callback = ref(async () => {})
const tileEditorKey = ref(0)

onMounted(async () => {
  langInit()
  const { user } = store.value.getters

  // get scopes
  if (user.id) {
    await store.value.dispatch('getScopes')

    Sentry.setUser({
      id: user.id,
      email: user.email,
      username: `${user.firstname} ${user.lastname}`,
    })
  }
})

const title = computed(() => {
  return lang.value.menu[route.name] || ''
})

function openRecommendationGroupEditor(cb) {
  callback.value = cb
  recommendationGroupEditorDialog.value = true
}

function closeRecommendationGroupEditor(newGroup) {
  callback.value(newGroup)
  recommendationGroupEditorDialog.value = false
}

function openTileEditor(cb = async () => {}) {
  tileEditorDialog.value = true
  callback.value = cb
}

function openDashboardEditor(cb = async () => {}) {
  dashboardEditorDialog.value = true
  callback.value = cb
}

async function closeTileEditor(useCallback = true) {
  if (useCallback) {
    await callback.value()
  }
  tileEditorDialog.value = false
  tileEditorKey.value += 1
}

function langInit() {
  let local = useLanguage()

  if (local.includes('-')) {
    local = local.split('-')[0]
  }

  lang.value.setLang(local)
}
</script>

<style>
@font-face {
  font-family: 'Roboto Light';
  src: local('Roboto'),
    url(https://fonts.googleapis.com/css?family=Roboto+Condensed)
      format('truetype');
}

@font-face {
  font-family: 'Mangueira Regular';
  src: url('assets/fonts/Mangueira-Regular.ttf');
}

@font-face {
  font-family: 'Mangueira Medium';
  src: url('assets/fonts/Mangueira-Medium.ttf');
}

@font-face {
  font-family: 'Mangueira Bold';
  src: url('assets/fonts/Mangueira-Bold.ttf');
}

@font-face {
  font-family: 'Mangueira Light';
  src: url('assets/fonts/Mangueira-Light.ttf');
}

.font-mangueira {
  font-family: 'Mangueira Regular', sans-serif;
}

.font-mangueira-b {
  font-family: 'Mangueira Bold', sans-serif;
}

.font-mangueira-m {
  font-family: 'Mangueira Medium', sans-serif;
}

.title-mangueira {
  font-family: 'Mangueira Regular', sans-serif;
  font-size: 24px;
}
.current-title {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
}
.rotated {
  transform: rotate(180deg);
}
.truncate {
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.force-margin-left {
  margin-left: 5px;
}

.force-margin {
  margin: 23px;
}

.link-no-decoration {
  text-decoration: none;
}

.v-btn {
  text-transform: none !important;
}

.mini-variant-icon-off {
  position: absolute;
  right: 15px;
  top: 20px;
}
.mini-variant-icon-on {
  position: absolute;
  right: 14px;
  top: 55px;
}

.login-background {
  background: linear-gradient(
    135deg,
    rgba(34, 131, 197, 0.44) 0%,
    rgba(113, 174, 82, 0.34) 69%,
    rgba(149, 193, 31, 0.44) 100%
  );
}

.pointer-hand {
  cursor: pointer;
}

.grid-background {
  background-image: url('assets/image/grid.png');
  background-size: cover;
}
.grid-background-contain {
  background-image: url('assets/image/grid.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.editable {
  cursor: pointer;
}
.editable:hover {
  color: #2183c6;
}
</style>
