<template>
  <tile-header :tile="tile" v-on="$listeners" :hover="hover">
    <template #description>
      <v-card-subtitle>
        <v-avatar size="18" class="mr-1" dark>
          <gravatar :size="15" :email="responsible.email"></gravatar>
        </v-avatar>
        <span>
          {{ responsible.firstname }}
          {{ responsible.lastname }}
        </span>
      </v-card-subtitle>
    </template>
  </tile-header>
</template>

<script setup>
import { defineProps, computed, toRefs } from 'vue'
import TileHeader from '@/components/tile/headers/TileHeader'
import Gravatar from 'vue-gravatar'

const props = defineProps({
  tile: {
    type: Object,
    default: () => ({}),
    hover: { type: Boolean, default: false },
  },
})

const { tile, hover } = toRefs(props)

const responsible = computed(() => tile.value.tile_data.responsible)
</script>
