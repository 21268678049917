<template>
  <p v-show="trend" class="caption mb-0" :class="color">
    <v-icon :color="iconColor">
      {{ icon }}
    </v-icon>
    {{ trend }}%
  </p>
</template>

<script setup>
import { computed, defineProps, toRefs } from 'vue'

const props = defineProps({
  trend: {
    type: Number,
    default: () => 0,
  },
})

const { trend } = toRefs(props)

const color = computed(() => (trend.value >= 0 ? 'red--text' : 'green--text'))

const icon = computed(() =>
  trend.value >= 0 ? 'mdi-trending-up' : 'mdi-trending-down',
)

const iconColor = computed(() => (trend.value >= 0 ? 'red' : 'green'))
</script>
