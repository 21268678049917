import RecommendationsService from '@/services/recommendations'
import RecommendationGroupService from '@/services/recommendationGroup'
import { mapActions, mapGetters } from 'vuex'
import ActivitiesService from '@/services/activities'
import moment from 'moment'
import IssueChannels from '@/services/issueChannels'
import { useDateFormatter } from '@/composables/date'

export default {
  async mounted() {
    await this.fetchRecommendationCategories()
    await this.getJiraChannels()
    this.categoriesLoading = false

    if (this.$route.params.recommendation_category) {
      await this.$store.dispatch('setRecommendationProperty', {
        property: 'recommendation_category_id',
        value:
          this.recommendationCategories.find(
            (r) => r.shortname === this.$route.params.recommendation_category,
          ).id || null,
      })
    }
  },
  data() {
    return {
      moment: moment,
      loading: true,
      channels: [],
      recommendations: [],
      confirmDeleteGroupDialog: false,
      recommendationGroups: [],
      categoriesLoading: true,
      deleteLoading: false,
      recommendationsLoading: true,
      recommendationGroupKey: 0,
      potentialSavingsHeaders: [
        { text: this.$lang.recommendations.resourceName, value: 'resourceId' },
        {
          text: this.$lang.recommendations.notes,
          value: 'notes',
          align: 'left',
          sortable: false,
        },
        { text: this.$lang.recommendations.cost, value: 'cost' },
        {
          text: this.$lang.recommendations.estimateSaving,
          value: 'estimateSaving',
          align: 'left',
        },
        {
          text: this.$lang.recommendations.savingRate,
          value: 'savingRate',
          align: 'left',
        },
      ],
      createLoading: false,
      recommendationToDelete: null,
      recommendationGroupToDelete: null,
      recommendationCategories: [],
      activitiesService: new ActivitiesService(),
      recommendationService: new RecommendationsService(),
      recommendationGroupService: new RecommendationGroupService(),
      issueChannelsService: new IssueChannels(),
      recommendationPriorities: ['normal', 'important', 'urgent'],
      recommendationImpacts: ['low', 'medium', 'high', 'critical'],
      recommendationStatuses: ['running', 'applied', 'postponed', 'rejected'],
      jiraStatuses: [],
      jiraStatus: '',
    }
  },
  methods: {
    ...mapActions([
      'setRecommendationProperty',
      'clearRecommendation',
      'toggleConfirmationDeleteDialog',
    ]),
    async getJiraChannels() {
      const response = await this.issueChannelsService.list()
      if (!response.data) return
      this.channels = response.data.map((channel) => {
        const config = JSON.parse(channel.config)
        return {
          id: channel.id,
          name: channel.name,
          key: config.project,
          date: useDateFormatter(channel.date),
          config,
        }
      })
    },
    async getChannelUsers() {
      const response = await this.issueChannelsService.getUsers(
        this.recommendation_jira_project.id,
        { user_type: ['administrator', 'viewer', 'member'] },
      )
      const users = response.data.map((type) => {
        if (!type.users) return
        return type.users.map((user) => {
          return {
            id: user.id,
            displayName: user.displayName,
            accountId: user.actorUser.accountId,
          }
        })
      })
      return users.flat()
    },
    async getChannelPriorities() {
      const response = await this.issueChannelsService.getPriorities(
        this.recommendation_jira_project.id,
      )
      if (!response.data) return
      return response.data.map((priority) => {
        return {
          id: priority.id,
          name: priority.name,
          statusColor: priority.statusColor,
        }
      })
    },
    async fetchRecommendations() {
      this.recommendationsLoading = true
      const { data } = await this.recommendationService.list()
      if (data && data.length) {
        this.recommendations = data
      }
      this.recommendationsLoading = false
    },

    async fetchRecommendationGroups() {
      if (!this.hasPermission('recommendationgroup.index')) return
      const data = await this.recommendationGroupService.list()
      if (data && data.length) {
        this.recommendationGroups = data
      }
    },

    async fetchRecommendationCategories() {
      const { data } = await this.recommendationService.listCategories()
      if (data && data.length) {
        this.recommendationCategories = data
      }
    },
    async fetchRecommendation(id) {
      const { data } = await this.recommendationService.get(id)
      this.jiraStatus = data.jira_issue ? data.jira_issue.status.name : ''
      if (data.hasOwnProperty('jira_statuses')) {
        this.jiraStatuses = data.jira_statuses.transitions.map(
          (status) => status.name,
        )
      }
      return {
        ...data,
        target: JSON.parse(data.target),
        activities: data.activities.map((e) => {
          return {
            message: this.getActivityMessage(e, id),
            date: moment(e.created_at).format('Do MMMM YYYY, HH:mm:ss '),
          }
        }),
      }
    },
    async fetchRecommendationGroup(id) {
      return await this.recommendationGroupService.get(id)
    },
    confirmDelete(id) {
      this.recommendationToDelete = { id: id }
      this.confirmDeleteDialog = true
    },
    confirmDeleteGroup(id) {
      this.recommendationGroupToDelete = { id: id }
      this.confirmDeleteGroupDialog = true
    },
    async deleteRecommendation(id) {
      const { code } = await this.recommendationService.delete(id)
      if (code === 200) {
        this.$route.name === 'recommendations-list'
          ? (this.recommendations = this.recommendations.filter(
              (e) => e.id !== id,
            ))
          : await this.$router.push({ name: 'recommendations-list' })
      }
      this.confirmDeleteDialog = false
      this.clearRecommendation()
    },

    async deleteRecommendationGroup(id) {
      this.deleteLoading = true
      await this.recommendationGroupService.delete(id)
      this.$route.name === 'recommendations-list'
        ? (this.recommendationGroups = this.recommendationGroups.filter(
            (e) => e.id !== id,
          ))
        : await this.$router.push({ name: 'recommendations-list' })
      this.confirmDeleteGroupDialog = false
      this.deleteLoading = false
    },

    async updateRecommendation(id, payload) {
      const { code } = await this.recommendationService.update(id, payload)
      return code === 200
    },

    async createRecommendation() {
      this.createLoading = true
      const { code } = await this.recommendationService.create(
        this.recommendation_to_create,
      )
      code == 200 &&
        this.clearRecommendation() &&
        this.$router.push({ name: 'recommendations-list' })
      this.createLoading = false
    },
    async setStatus(id, status) {
      const { code } = await this.recommendationService.setStatus(id, {
        status: status,
      })
      return code === 200
    },
    async setJiraStatus(recommendation_id, status) {
      const jira_status = this.recommendation.jira_statuses.transitions.find(
        (e) => e.name === status,
      )
      const { code } = await this.issueChannelsService.setIssueStatus(
        recommendation_id,
        { jira_status },
      )
      this.jiraStatus = status
      return code === 200
    },
    async setImpact(id, impact) {
      const { code } = await this.recommendationService.setImpact(id, {
        impact: impact,
      })

      return code === 200
    },
    async check(id, finalSaving) {
      const { code } = await this.recommendationService.check(id, {
        finalSavings: finalSaving,
      })

      return code === 200
    },
    async setPriority(id, priority) {
      const { code } = await this.recommendationService.setPriority(id, {
        priority: priority,
      })

      return code === 200
    },
    async setDueDate(id, dueDate) {
      const { code } = await this.recommendationService.setDueDate(id, {
        due_at: dueDate,
      })

      return code === 200
    },
    async setAssignee(id, assigneeId) {
      const { code } = await this.recommendationService.setAssignee(id, {
        assignee_id: assigneeId,
      })

      return code === 200
    },

    getActivityMessage(activity, id = null) {
      let description = this.$lang.recommendations.activities[activity.Log_name]
        .replace('**ID**', activity.subject_id || id)
        .replace(
          '**PROPERTY**',
          this.parseActivityProperties(activity.properties),
        )
      return `${activity.causer.firstname} ${activity.causer.lastname} ${description}`
    },
    parseActivityProperties(properties) {
      return Object.keys(properties)[0] == 'due_at'
        ? moment(Object.values(properties)[0]).format('DD MMMM YYYY')
        : this.$lang.recommendations[Object.values(properties)[0]] ||
            Object.values(properties)[0]
    },
  },
  computed: {
    ...mapGetters([
      'recommendation_saving_rate',
      'recommendation_to_create',
      'recommendation_confirmation_delete_dialog_open',
      'recommendation_jira_project',
      'recommendation_jira_assignee',
    ]),

    confirmDeleteDialog: {
      get() {
        return this.recommendation_confirmation_delete_dialog_open
      },
      set() {
        this.toggleConfirmationDeleteDialog()
      },
    },
    hasChannels() {
      return this.channels.length
    },
    savingRate: {
      set(v) {
        this.setRecommendationProperty({
          property: 'savingRate',
          value: v,
        })
      },
      get() {
        return this.recommendation_saving_rate
      },
    },
  },
}
