<template>
  <v-dialog :value="value" @click:outside="toggle" max-width="450px">
    <v-card>
      <v-card-title>{{ $lang.company.switchCompanyTitle }}</v-card-title>
      <v-card-text>
        {{ $lang.company.switchCompanyText }}
      </v-card-text>
      <v-divider />
      <v-text-field
        :label="$lang.catalog.search"
        v-model="search"
        hide-details
        filled
        rounded
        dense
        style="border-radius: 0px"
      />
      <v-divider />
      <v-simple-table dense>
        <template #default>
          <thead>
            <tr>
              <th class="text-left">{{ $lang.company.companies }}</th>
            </tr>
          </thead>
        </template>
      </v-simple-table>
      <v-progress-linear v-if="loading" indeterminate />
      <v-list max-height="300px" style="overflow-y: scroll">
        <v-list-item
          dense
          v-for="company in companiesFiltered"
          :key="company.id"
          @click="switchCompany(company.id)"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ company.name }}
            </v-list-item-title>
            <v-list-item-subtitle
              v-if="company.id === $store.getters.user.company_id"
            >
              {{ $lang.company.currentCompany }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              icon
              dense
              v-if="company.id !== $store.getters.user.company_id"
              @click="switchCompany(company.id)"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-card v-intersect="onScroll"></v-card>
        <v-card
          class="text-center caption pt-2"
          flat
          color="transparent"
          v-if="page == totalPage && !loading"
        >
          {{ $lang.company.endOfList }}
        </v-card>
      </v-list>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn @click="toggle" elevation="0" color="primary" width="10rem">
          {{ $lang.button.close }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CompanyService from '@/services/company'
import UserService from '@/services/user'
import { session } from '@/mixins/session'
import { mapGetters } from 'vuex'

export default {
  name: 'CompanyDialog',
  props: {
    value: Boolean,
  },
  mixins: [session],
  data() {
    return {
      companyService: new CompanyService(),
      userService: new UserService(),
      companies: [],
      page: 1,
      totalPage: -1,
      search: '',
      loading: false,
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
        },
        {
          text: '',
          value: 'action',
          align: 'right',
          sortable: false,
        },
      ],
    }
  },
  watch: {
    async value() {
      if (this.companies.length === 0) {
        await this.load()
      }
    },
  },
  computed: {
    ...mapGetters(['hasPermission']),

    companiesFiltered() {
      if (this.search === '') {
        return this.companies
      }

      return this.companies.filter((company) => {
        return company.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
  },
  methods: {
    toggle() {
      this.$emit('input', false)
    },

    async load() {
      this.loading = true

      if (this.hasPermission('switch.company')) {
        const { data, last_page } = await this.companyService.list(this.page)
        this.companies = this.companies.concat(data)
        this.totalPage = last_page
      }

      this.loading = false
    },

    async switchCompany(id) {
      const userId = this.$store.getters.user.id
      await this.userService.switchCompany({
        userId,
        companyId: id,
      })
      const token = localStorage.getItem('api_token')
      await this.startSession(token, userId, id, false)
      this.$router.go()
    },

    async onScroll(entries) {
      if (entries[0].isIntersecting && this.page < this.totalPage) {
        this.page++
        await this.load()
      }
    },
  },
}
</script>
