<template>
  <v-card height="100%">
    <v-container class="px-6">
      <v-row justify="center">
        <v-col cols="12" align-self="start">
          <v-toolbar flat outlined>
            <v-toolbar-title>
              {{ $lang.dashboard.createNewDashboard }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn icon text @click="$emit('close')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>
        <v-col cols="12" md="10" align-self="start">
          <form-step
            :title="$lang.dashboard.dashboardName"
            :description="$lang.dashboard.dashboardNameDescription"
            number="1"
          >
            <template #content>
              <v-text-field
                outlined
                rounded
                dense
                hide-details
                :placeholder="$lang.dashboard.dashboardName"
                v-model="dashboard.name"
              ></v-text-field>
            </template>
          </form-step>
          <form-step
            :title="$lang.dashboard.dashboardDescription"
            :description="$lang.dashboard.dashboardDescriptionDescription"
            number="2"
          >
            <template #content>
              <v-text-field
                outlined
                rounded
                dense
                hide-details
                :placeholder="$lang.dashboard.dashboardDescription"
                v-model="dashboard.description"
              ></v-text-field>
              <v-btn
                block
                color="green"
                depressed
                :disabled="!canSaveDashboard"
                @click="createDashboard"
                class="white--text mt-10"
              >
                <span class="text-overline">{{ $lang.button.save }}</span>
              </v-btn>
            </template>
          </form-step>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script setup>
import { computed, ref, getCurrentInstance, defineEmits } from 'vue'

import DashboardService from '@/services/dashboard'
const service = new DashboardService()

const instance = getCurrentInstance().proxy
const $router = computed(() => instance.$router)

const emit = defineEmits(['close'])

const dashboard = ref({ name: '', description: '' })

async function createDashboard() {
  const { data } = await service.createDashboard({
    name: dashboard.value.name,
    description: dashboard.value.description,
    tiles: [],
  })

  if (data && data.id) {
    await $router.value.push({
      path: `/custom-dashboard/${data.id}`,
    })
    await emit('close')
  }
}

const canSaveDashboard = computed(() => dashboard.value.name.length > 0)
</script>
