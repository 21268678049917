<template>
  <v-col cols="12">
    <form-step
      :title="$lang.dashboard.preview"
      :description="$lang.dashboard.previewDescription"
      number="0"
    >
      <template #content>
        <v-col cols="11" class="grey lighten-2">
          <v-card outlined height="55vh" color="grey lighten-2" width="50vw">
            <v-expand-transition>
              <v-alert dense type="info" v-if="showAlert">
                {{ $lang.dashboard.noData }}
              </v-alert>
            </v-expand-transition>
            <v-row class="fill-height" justify="center" align="center">
              <v-slide-x-reverse-transition hide-on-leave>
                <div class="text-center" v-show="!tilePreview">
                  <v-icon x-large class="mx-2">mdi-chart-bar</v-icon>
                  <v-icon x-large class="mx-2">mdi-table</v-icon>
                  <v-icon x-large class="mx-2">mdi-chart-pie</v-icon>
                  <v-icon x-large class="mx-2">mdi-numeric</v-icon>
                </div>
              </v-slide-x-reverse-transition>
              <v-slide-x-transition hide-on-leave>
                <v-col
                  cols="12"
                  lg="10"
                  v-show="tilePreview !== false"
                  class="text-center"
                >
                  <tile-wrapper
                    v-if="tilePreview"
                    :tile="tilePreview"
                    v-on="$listeners"
                    preview
                  ></tile-wrapper>
                </v-col>
              </v-slide-x-transition>
            </v-row>
          </v-card>
        </v-col>
      </template>
    </form-step>
  </v-col>
</template>

<script setup>
import { defineProps, toRefs, computed } from 'vue'

import TileWrapper from '@/components/tile/TileWrapper'

const props = defineProps({
  tile: { type: Object, default: () => ({}) },
  showAlert: { type: Boolean, default: false },
})

const { tile } = toRefs(props)

const tilePreview = computed(() => {
  const { settings, tile_data } = tile.value
  if (!tile_data || !settings.contentType || !settings.period) {
    return false
  }
  return {
    ...tile.value,
    settings: JSON.stringify({ ...settings }),
  }
})
</script>
