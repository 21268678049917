<template>
  <v-row justify="center" class="fill-height">
    <v-col cols="12" align-self="center" class="rounded-xl">
      <v-expand-transition>
        <charts :options="defaultChart" v-show="!loading"></charts>
      </v-expand-transition>
      <v-expand-transition>
        <v-progress-linear
          indeterminate
          height="10"
          v-show="loading"
          color="primary"
        ></v-progress-linear>
      </v-expand-transition>
    </v-col>
    <v-col class="py-4"></v-col>
  </v-row>
</template>

<script setup>
import PipelineService from '@/services/pipeline'
import {
  computed,
  defineProps,
  getCurrentInstance,
  onMounted,
  ref,
  toRefs,
  watch,
} from 'vue'
import { defaultChartModel } from '@/components/tile/DefaultChartModel'
import { useCostFormatter } from '@/composables/cost'

const service = new PipelineService()
const instance = getCurrentInstance().proxy
const $lang = computed(() => instance.$lang)
const $store = computed(() => instance.$store)
const loading = ref(false)
const scopesData = ref([])
const emit = defineEmits(['show-alert'])

const props = defineProps({
  data: { type: Object, default: () => ({}) },
  settings: { type: Object, default: () => ({}) },
})
const { data, settings } = toRefs(props)

const defaultChart = computed(() => {
  return {
    ...defaultChartModel,
    xAxis: {
      type: 'category',
    },
    series: generateChartData(),
    chart: {
      ...defaultChartModel.chart,
      type: settings.value.timeChartType || 'column',
    },
    tooltip: {
      formatter: function () {
        let tooltip = ''
        tooltip += `<b>${this.series.name}</b> : ${useCostFormatter(this.y)}`
        return tooltip
      },
    },
  }
})
onMounted(async () => {
  await fetchScopesData()
})
function generateChartData() {
  const scopes = Array.from(
    new Set(scopesData.value.map((scopeData) => scopeData.name)),
  )
  const resourceNames = Array.from(
    new Set(
      scopesData.value.flatMap((scopeData) =>
        scopeData.data.map((resourceData) => resourceData.value),
      ),
    ),
  )
  return resourceNames.map((resourceName) => {
    const data = scopes.map((scope) => {
      const scopeData = scopesData.value.find(
        (scopeData) => scopeData.name === scope,
      )
      const resourceData = scopeData
        ? scopeData.data.find((resource) => resource.value === resourceName)
        : null
      return [scope, resourceData ? resourceData.cost : 0]
    })

    return { data, name: resourceName }
  })
}

async function fetchScopesData() {
  loading.value = true
  scopesData.value = []
  const scopes = data.value.data_scope.map((scope) => {
    return {
      name: scope.name,
      blocks: scope.blocks,
    }
  })
  await fetchChartData(scopes)
  loading.value = false
}
async function fetchChartData(scopes) {
  const payload = {
    from: data.value.start_date,
    to: data.value.end_date,
    group: data.value.explorer.group_by,
    scope: JSON.stringify(scopes),
    offset: 0,
  }
  scopesData.value = await service.aggregate('explorer/tile_group_by', payload)
  if (!scopesData.value.length) {
    return emit('show-alert')
  }
}
watch(data, async () => await fetchScopesData(), { deep: true })
</script>
