import HttpClient from '@/services/base'

export default class DashboardService extends HttpClient {
  constructor() {
    super()
  }

  async listDashboards() {
    return await this.request({
      url: '/dashboards',
      method: 'GET',
    })
  }

  async getDashboard(id) {
    return await this.request({
      url: `/dashboards/${id}`,
      method: 'GET',
    })
  }

  async createDashboard(data) {
    return await this.request({
      url: '/dashboards',
      method: 'POST',
      data,
    })
  }

  async saveDashboardPositions(id, data) {
    return await this.request({
      url: `/dashboards/${id}/positions`,
      method: 'PATCH',
      data,
    })
  }

  async updateDashboard() {}

  async deleteDashboard(id) {
    return await this.request({
      url: `/dashboards/${id}`,
      method: 'DELETE',
    })
  }

  async listTiles() {}

  async createTile(data) {
    return await this.request({
      url: '/dashboard-tiles',
      method: 'POST',
      data,
    })
  }

  async attachTile(dashboardId, tileId, data) {
    return await this.request({
      url: `/dashboards/${dashboardId}/attach/${tileId}`,
      method: 'PATCH',
      data,
    })
  }

  async detachTile(dashboardId, tileId, data) {
    return await this.request({
      url: `/dashboards/${dashboardId}/detach/${tileId}`,
      method: 'PATCH',
      data,
    })
  }

  async updateTile() {}

  async deleteTile() {}
}
