import moment from 'moment'

export const budgetTile = {
  props: {
    data: { type: Object, default: () => ({}) },
    settings: { type: Object, default: () => ({}) },
  },
  computed: {
    trendColor() {
      return this.data.trend === 'OK'
        ? 'green'
        : this.data.trend === 'WATCH'
        ? 'orange'
        : 'red'
    },
    trendIcon() {
      return this.data.trend === 'OK'
        ? 'checkbox-marked-circle-outline'
        : this.data.trend === 'WATCH'
        ? 'alert-circle-outline'
        : 'close-circle-outline'
    },
    periods() {
      const now = moment()
      return this.settings.period === 'current'
        ? this.data.periods.filter(
            (p) => moment(p.start_date).month() === now.month(),
          )
        : this.settings.period === 'previous'
        ? this.data.periods.filter((p) => moment(p.end_date) < now)
        : this.data.periods
    },
    consumption() {
      return this.periods.reduce((acc, cur) => (acc += cur.realized_cost), 0)
    },
    allocated() {
      return this.periods.reduce(
        (acc, cur) =>
          (acc += cur.reforecast ? cur.reforecast : cur.allocated_cost),
        0,
      )
    },
    percentage() {
      return (this.consumption / this.allocated) * 100
    },
    difference() {
      return this.periods.reduce(
        (acc, cur) =>
          (acc +=
            cur.realized_cost -
            (cur.reforecast ? cur.reforecast : cur.allocated_cost)),
        0,
      )
    },
    startDate() {
      return this.periods[0]?.start_date
    },
    endDate() {
      return this.periods[this.periods.length - 1]?.end_date
    },
  },
}
