import 'regenerator-runtime/runtime'
import './filters'
import Lang from 'vuejs-localization'
import Vue from 'vue'
import Gravatar from 'vue-gravatar'
import FormStep from '@/components/shared/FormStep'
import CountryFlag from 'vue-country-flag'
import * as Sentry from '@sentry/vue'

import VueHtml2Canvas from 'vue-html2canvas'

// load tools
import vuetify from './plugins/vuetify'
import highcharts from './plugins/highcharts'
import router from './route'
import store from './store'

import { useLocaleSentryOptions } from '@/composables/locale'

// load default vue (for render)
import App from './App.vue'

// global method
import PageInfo from './components/layouts/PageInfo'
import DismissableFooter from '@/components/DismissableFooter'

// register global components
Vue.component('PageInfo', PageInfo)
Vue.component('v-gravatar', Gravatar)
Vue.component('form-step', FormStep)
Vue.component('dismissable-footer', DismissableFooter)
Vue.component('country-flag', CountryFlag)

//config element
Vue.config.productionTip = false

Lang.requireAll(require.context('../lang', true, /\.js$/))

Vue.use(Lang)
Vue.use(VueHtml2Canvas)

Sentry.init({
  Vue,
  dsn: 'https://24e3d731d4b36e00116895d2ed18a08e@o1279201.ingest.sentry.io/4506812527542272',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: 'light',
      locale: 'fr',
      ...useLocaleSentryOptions(),
    }),
  ],

  environment: process.env.VUE_APP_ENV || 'development',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['*'],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

// create vue (with tools and default vue)
new Vue({
  vuetify,
  highcharts,
  router,
  store,
  render: (h) => h(App),
  components: {
    'page-info': PageInfo,
  },
}).$mount('#app')
