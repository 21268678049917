<template>
  <tile-header :tile="tile" v-on="$listeners" :hover="hover">
    <template #description>
      <v-card-subtitle class="text-left">
        {{ $lang.contentTypes[contentType] }}
        <span class="text-lowercase" v-show="tile.tile_data.explorer.group_by">
          {{ $lang.dashboard.groupBy[tile.tile_data.explorer.group_by] }}
        </span>
        <div v-show="tile.tile_data.type !== 'multi_scope_data_view'">
          <span v-show="tile.tile_data.explorer.granularity">
            {{ $lang.dashboard.and }}
          </span>
          <span
            class="text-lowercase"
            v-show="tile.tile_data.explorer.granularity"
          >
            {{
              $lang.dashboard.granularity[tile.tile_data.explorer.granularity]
            }}
          </span>
        </div>

        <span v-show="timeRange">
          -
          <i>{{ $lang.dashboard.periodicity[timeRange] }}</i>
        </span>
      </v-card-subtitle>
    </template>
  </tile-header>
</template>

<script setup>
import { defineProps, toRefs, computed } from 'vue'
import TileHeader from '@/components/tile/headers/TileHeader'

const props = defineProps({
  tile: { type: Object, default: () => ({}) },
  settings: { type: Object, default: () => ({}) },
  hover: { type: Boolean, default: false },
})

const { tile, settings } = toRefs(props)

const timeRange = computed(() => tile.value.tile_data.time_range)
const contentType = computed(() => settings.value.contentType)
</script>
