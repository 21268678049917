<template>
  <v-row>
    <v-col cols="12">
      <form-step
        :title="$lang.dashboard.selectSection"
        :description="$lang.dashboard.selectSectionDescription"
        number="1"
      >
        <template #content>
          <v-select
            v-model="section"
            :placeholder="$lang.dashboard.selectSection"
            :items="sections"
            item-text="name"
            item-key="id"
            outlined
            hide-details
            return-object
            rounded
            dense
            @change="setContentType(section.id)"
          ></v-select>
        </template>
      </form-step>
      <form-step
        :title="$lang.dashboard.sectionTitle"
        :description="$lang.dashboard.sectionTitleDescription"
        number="2"
      >
        <template #content>
          <v-text-field
            outlined
            rounded
            dense
            hide-details
            :placeholder="$lang.dashboard.sectionTitlePlaceholder"
            v-model="title"
            @input="setTile"
          ></v-text-field>
        </template>
      </form-step>
      <form-step
        :title="$lang.dashboard.sectionDescription"
        :description="$lang.dashboard.sectionDescriptionDescription"
        number="3"
      >
        <template #content>
          <v-textarea
            outlined
            rounded
            dense
            hide-details
            :placeholder="$lang.dashboard.sectionDescriptionPlaceholder"
            v-model="description"
            @input="setTile"
          ></v-textarea>
        </template>
      </form-step>
      <form-step
        :title="$lang.dashboard.selectSectionColor"
        :description="$lang.dashboard.selectSectionColorDescription"
        number="4"
      >
        <template #content>
          <v-select
            v-model="color"
            :placeholder="$lang.dashboard.selectSectionColor"
            :items="colors"
            item-text="name"
            item-key="hex"
            outlined
            hide-details
            return-object
            rounded
            dense
            @change="setTile()"
          >
            <template #item="{ item }">
              <div
                :style="`background: ${item.hex}; width: 100%`"
                class="py-4"
              ></div>
            </template>
            <template #selection>
              <div class="d-flex align-center">
                <v-icon :color="color.hex" class="pr-2">mdi-circle</v-icon>
                <span class="pt-1">{{ $lang.colors[color.name] }}</span>
              </div>
            </template>
          </v-select>
        </template>
      </form-step>
    </v-col>
  </v-row>
</template>

<script setup>
import {
  ref,
  computed,
  getCurrentInstance,
  defineEmits,
  toRefs,
  onMounted,
} from 'vue'

const props = defineProps({
  tile: {
    type: Object,
    required: true,
  },
})
const { tile } = toRefs(props)
const instance = getCurrentInstance().proxy
const $lang = computed(() => instance.$lang)
const emit = defineEmits(['edit-type'])

onMounted(() => {
  emit('edit-type', 'dashboard_section')
})
const sections = [
  { id: 'SectionTitle', name: $lang.value.dashboard.sectionWithTitle },
]
const section = ref(null)

const colors = [
  { hex: '#0070d9', name: 'blue' },
  { hex: '#06A21E', name: 'green' },
  { hex: '#ffb91e', name: 'yellow' },
  { hex: '#f77c00', name: 'orange' },
  { hex: '#e0485e', name: 'pink' },
  { hex: '#ea1f26', name: 'red' },
]
const color = ref(colors[0])

const title = ref(null)
const description = ref(null)

async function setContentType(contentType) {
  tile.value.settings.contentType = contentType
  tile.value.settings.period = 'None'
  tile.value.size = 'x-large'
  tile.value.tile_data = {
    ...tile.value.tile_data,
    title: title.value,
    description: description.value,
    color: color.value,
  }
}
async function setTile() {
  tile.value.tile_data = {
    ...tile.value.tile_data,
    title: title.value,
    description: description.value,
    color: color.value,
  }
}
</script>
