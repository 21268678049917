import { chartOptions } from '@/components/chartOptions'

export class customChartOptions {
  constructor() {
    this.chart = {
      type: 'pie',
      backgroundColor: 'transparent',
      height: '300px',
    }
    this.legend = {
      enabled: true,
    }
    this.plotOptions = {
      series: {
        dataLabels: {
          enabled: true,
        },
      },
      areaspline: {
        stacking: 'normal',
        borderWidth: 0,
        fillOpacity: 0.8,
      },
      column: {
        stacking: 'normal',
        borderWidth: 0,
        fillOpacity: 0.7,
      },
      line: {
        borderWidth: 0,
      },
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        layout: 'vertical',
      },
      treemap: {
        dataLabels: { enabled: true },
        borderWidth: 0,
        fillOpacity: 0.7,
      },
    }
    this.title = { text: '' }
    this.exporting = { enabled: false }
    this.credits = { enabled: false }
    this.colors = [...chartOptions.colors]
    this.series = []
    this.xAxis = {
      categories: [],
      labels: {
        enabled: true,
        formatter: (label) => '',
      },
      plotBands: [],
    }
  }
}
