<template>
  <v-row justify="center" class="fill-height">
    <v-col cols="12" align-self="center" class="rounded-xl">
      <v-expand-transition>
        <charts :options="defaultChart" v-show="!loading"></charts>
      </v-expand-transition>
      <v-expand-transition>
        <v-progress-linear
          indeterminate
          height="10"
          v-show="loading"
          color="primary"
        ></v-progress-linear>
      </v-expand-transition>
    </v-col>
    <v-col class="py-4"></v-col>
  </v-row>
</template>

<script setup>
import {
  computed,
  defineProps,
  getCurrentInstance,
  onMounted,
  ref,
  toRefs,
  watch,
} from 'vue'

import {
  useDateFormatter,
  useDateMonthFormatter,
  useYearDateFormatter,
} from '@/composables/date'
import { useCostFormatter } from '@/composables/cost'
import PipelineService from '@/services/pipeline'
import { defaultChartModel } from '@/components/tile/DefaultChartModel'

const service = new PipelineService()
const instance = getCurrentInstance().proxy
const $lang = computed(() => instance.$lang)
const $store = computed(() => instance.$store)
const emit = defineEmits(['show-alert'])

const props = defineProps({
  data: { type: Object, default: () => ({}) },
  settings: { type: Object, default: () => ({}) },
  showPreview: { type: Boolean, default: false },
})
const scopeData = ref([])
const { data, settings } = toRefs(props)

const defaultChart = computed(() => {
  return {
    ...defaultChartModel,
    xAxis: {
      title: {
        text: `${$lang.value.explorer.yAxisChartTitle} ${$store.value.getters.company.currency}`,
      },
      type: 'datetime',
      labels: {
        enabled: true,
        formatter: (label) => {
          return dateFormatter(label.value)
        },
      },
    },
    chart: {
      ...defaultChartModel.chart,
      type: settings.value.timeChartType || 'column',
    },
    tooltip: {
      formatter: function () {
        let tooltip = `${dateFormatter(this.x)}<br>`
        tooltip += `<b>${this.series.name}</b> : ${useCostFormatter(this.y)}`
        return tooltip
      },
    },
    series: scopeData.value.map((serie) => ({
      name: serie.name,
      data: serie.data.map(({ date, cost }) => ({
        x: new Date(date),
        y: cost,
      })),
    })),
  }
})
const loading = ref(false)

onMounted(async () => {
  await fetchScopeData()
})
async function fetchScopeData() {
  loading.value = true
  scopeData.value = []
  if (Array.isArray(data.value.data_scope)) {
    await fetchChartData(data.value.data_scope[0])
  } else {
    await fetchChartData(data.value.data_scope)
  }
  loading.value = false
}
async function fetchChartData(scope) {
  loading.value = true
  const payload = {
    from: data.value.start_date,
    to: data.value.end_date,
    group: data.value.explorer.group_by,
    scope: JSON.stringify([{ name: scope.name, blocks: scope.blocks }]),
    offset: 0,
  }
  const values = await service.aggregate('explorer/tile_group_by', payload)
  if (!values.length) {
    return emit('show-alert')
  }
  payload.granularity = data.value.explorer.granularity
  payload.values = values[0].data.map((v) => v.value)
  payload.scope = scope.blocks
  const response = await service.aggregate(
    'explorer/charts',
    {},
    'POST',
    payload,
  )
  if (response && response.length) {
    scopeData.value = response
  }
}
function dateFormatter(value) {
  return data.value.explorer.granularity === 'month'
    ? useDateMonthFormatter(value)
    : data.value.explorer.granularity === 'year'
    ? useYearDateFormatter(value)
    : useDateFormatter(value)
}

watch(data, async () => await fetchScopeData(), { deep: true })
</script>
