import moment from 'moment'

const isoFormat = 'YYYY-MM-DD'

export function useTimeRange(days = 30) {
  return {
    startDate: moment().subtract(days, 'days').format(isoFormat),
    endDate: moment().format(isoFormat),
  }
}
export function useIsoFormatter(date) {
  return moment(date).format(isoFormat)
}

const fullFormat = 'Do MMM YYYY'

export function useDateFormatter(date) {
  return moment(date).format(fullFormat)
}

const monthFormat = 'MMM YYYY'

export function useDateMonthFormatter(date) {
  return moment(date).format(monthFormat)
}

const yearFormat = 'YYYY'

export function useYearDateFormatter(date) {
  return moment(date).format(yearFormat)
}

const hourFormat = 'Do MMMM YYYY, HH:mm'

export function useDateHourFormatter(date) {
  return moment(date).format(hourFormat)
}

const shortYearsFormat = 'DD MMM YY'

export function useShortYearsDateFormatter(date) {
  return moment(date).format(shortYearsFormat)
}

const dateWithoutYearFormat = 'Do MMM'

export function useDateWithoutYearFormatter(date) {
  return moment(date).format(dateWithoutYearFormat)
}

export function useDateFormatterFromGranularity(date, granularity) {
  switch (granularity) {
    case 'day':
      return useDateFormatter(date)
    case 'month':
      return useDateMonthFormatter(date)
    case 'year':
      return useYearDateFormatter(date)
    default:
      return useDateFormatter(date)
  }
}
