import { chartOptions } from '@/components/chartOptions'

export const defaultChartModel = {
  chart: {
    backgroundColor: 'transparent',
    height: '300px',
  },
  title: { text: '' },
  legend: { enabled: true },
  plotOptions: {
    areaspline: {
      stacking: 'normal',
      borderWidth: 0,
      fillOpacity: 0.8,
    },
    column: {
      stacking: 'normal',
      borderWidth: 0,
      fillOpacity: 0.7,
    },
    line: {
      borderWidth: 0,
    },
    pie: {
      dataLabels: { enabled: false },
      borderWidth: 0,
      fillOpacity: 0.7,
    },
    treemap: {
      dataLabels: { enabled: true },
      borderWidth: 0,
      fillOpacity: 0.7,
    },
  },
  series: [],
  exporting: { enabled: false },
  credits: { enabled: false },
  colors: [...chartOptions.colors],
  yAxis: { title: { text: '' } },
}
