<template>
  <v-snackbar
    top
    :value="store.getters.snackbar"
    @input="store.commit('setSnackbar', false)"
    :timeout="4000"
    class="d-flex justify-center"
  >
    <v-icon v-if="store.getters.icon" small class="pr-2">
      {{ store.getters.icon }}
    </v-icon>
    {{ store.getters.message }}
  </v-snackbar>
</template>

<script setup>
import { computed, getCurrentInstance, onMounted } from 'vue'

const instance = getCurrentInstance()

const store = computed(() => instance.proxy.$store)

onMounted(() => {
  store.value.commit('setSnackbar', false)
})
</script>
