export class TileModel {
  constructor() {
    this.name = ''
    this.description = ''
    this.tile_data = {
      type: null,
      data_scope: null,
      multiScope: false,
      explorer: {
        granularity: 'day',
        group_by: 'provider',
      },
    }
    this.settings = {
      contentType: '',
      period: null,
      timeChartType: 'column',
    }
    this.size = 'small'
  }
}
