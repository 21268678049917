<template>
  <v-card>
    <v-toolbar flat outlined>
      <v-toolbar-title>{{ $lang.dashboard.createNewTile }}</v-toolbar-title>
      <v-spacer />
      <v-btn icon text @click="$emit('close', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container>
      <tile-type-selector @set-tile-type="setTileType"></tile-type-selector>
    </v-container>
    <v-container>
      <v-row align="start" justify="center">
        <v-slide-x-reverse-transition>
          <v-col cols="12" md="5">
            <component
              :is="components[tileType]"
              :tile="tile"
              @show-alert="showAlert = true"
            ></component>
            <v-row>
              <v-col cols="12">
                <div v-for="formStep in formSteps" :key="formStep.title">
                  <form-step
                    :title="$lang.dashboard[formStep.title]"
                    :description="
                      $lang.dashboard[`${formStep.title}Description`]
                    "
                    :number="formStep.number"
                  >
                    <template #content>
                      <v-text-field
                        v-if="formStep.content === 'field'"
                        outlined
                        dense
                        :placeholder="$lang.dashboard[formStep.title]"
                        v-model="tile[formStep.model]"
                      ></v-text-field>
                      <dashboard-table
                        v-if="formStep.content === 'dashboardTable'"
                        @set-dashboards="setDashboards"
                      ></dashboard-table>
                      <v-btn
                        v-if="formStep.content === 'save'"
                        block
                        depressed
                        color="green"
                        :disabled="!canSaveTile"
                        :loading="loading"
                        @click="saveTile"
                        class="mt-2 white--text"
                      >
                        <span class="text-overline">enregistrer et fermer</span>
                      </v-btn>
                    </template>
                  </form-step>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-slide-x-reverse-transition>
        <v-col
          cols="12"
          md="7"
          align-self="start"
          class="py-0 preview-container"
        >
          <v-row style="position: fixed; min-width: 50vw">
            <tile-preview
              :tile="tile"
              @set-tile-size="setTileSize"
              :show-alert="showAlert"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script setup>
import DashboardTable from '../dashboard/DashboardTable.vue'
import TilePreview from './TilePreview.vue'
import TileTypeSelector from './TileTypeSelector.vue'
import DashboardService from '@/services/dashboard'
import DataViewService from '@/services/dataView'
import DashboardSection from '@/services/dashboardSection'
import { computed, onMounted, ref, watch } from 'vue'
import { TileModel } from '@/components/tile/TileModel'

const dashboardService = new DashboardService()
const dataViewService = new DataViewService()
const dashboardSectionService = new DashboardSection()
const loading = ref(false)
const tileType = ref('BudgetTile')
const dashboardSelection = ref([])
const tile = ref(new TileModel())
const emit = defineEmits(['close'])
const showAlert = ref(false)

onMounted(() => {
  window.scrollTo(0, 0)
  tile.value = new TileModel()
})
const components = {
  BudgetTile: require('@/components/tile/forms/BudgetTileForm.vue').default,
  ExplorerTile: require('@/components/tile/forms/ExplorerTileForm.vue').default,
  SectionTile: require('@/components/tile/forms/SectionTileForm.vue').default,
}
const formSteps = [
  {
    title: 'tileName',
    model: 'name',
    number: '4',
    content: 'field',
  },
  {
    title: 'tileDescription',
    model: 'description',
    number: '5',
    content: 'field',
  },
  {
    title: 'addToDashboards',
    number: '0',
    content: 'dashboardTable',
  },
  {
    title: 'saveTile',
    number: '0',
    content: 'save',
  },
]
const tileSubjectType = computed(() => {
  return {
    ExplorerTile: tile.value.tile_data.multiScope
      ? 'multi_scope_data_view'
      : 'data_view',
    RecommendationTile: 'recommendation',
    BudgetTile: 'budget',
    AlertTile: 'alert',
    SectionTile: 'dashboard_section',
  }[tileType.value]
})
const canSaveTile = computed(() => {
  return (
    tile.value.name.length &&
    tile.value.settings.contentType &&
    tile.value.settings.period
  )
})
async function saveTile() {
  loading.value = true
  if (tileType.value === 'ExplorerTile' && !tile.value.tile_data.multiScope) {
    await createDataView()
  } else if (
    tileType.value === 'ExplorerTile' &&
    tile.value.tile_data.multiScope
  ) {
    await createMultiScopeDataView()
  } else if (tileType.value === 'SectionTile') {
    await createDashboardSection()
  } else {
    await createTile(tile.value.tile_data.id)
  }
}
async function createMultiScopeDataView() {
  const response = await dataViewService.multiScopeCreate({
    name: tile.value.name,
    ...(tile.value.description && { description: tile.value.description }),
    time_range: tile.value.tile_data.time_range,
    granularity: tile.value.tile_data.explorer.granularity,
    group_by: tile.value.tile_data.explorer.group_by,
    scopes: tile.value.tile_data.data_scope.map((scope) => scope.id),
  })
  if (response && response.id) {
    await createTile(response.id)
  }
}
async function createDataView() {
  const { data } = await dataViewService.create({
    name: tile.value.name,
    time_range: tile.value.tile_data.time_range,
    granularity: tile.value.tile_data.explorer.granularity,
    group_by: tile.value.tile_data.explorer.group_by,
    data_scope_id: tile.value.tile_data.data_scope[0].id,
  })

  if (data && data.id) {
    await createTile(data.id)
  }
}
async function createDashboardSection() {
  const response = await dashboardSectionService.create({
    title: tile.value.tile_data.title,
    description: tile.value.tile_data.description,
    color: tile.value.tile_data.color.hex,
    sectionType: 'dashboard_section_title',
  })
  if (response && response.id) {
    await createTile(response.id)
  }
}
async function createTile(subjectId) {
  const { data } = await dashboardService.createTile({
    name: tile.value.name,
    subject_type: tileSubjectType.value,
    subject_id: subjectId,
    description: tile.value.description,
    settings: JSON.stringify(tile.value.settings),
  })
  data &&
    (await Promise.all(
      dashboardSelection.value.map(async (value) => {
        await attachTile(value, data.id)
      }),
    ))
  emit('close')
}
async function attachTile(dashboard, tileId) {
  dashboard.loading = true
  const { data } = await dashboardService.attachTile(dashboard.id, tileId, {
    position: dashboard.tile_count + 1,
    size: tile.value.size,
  })

  if (data && data.id) {
    dashboard.loading = false
  }
}
function setTileType(payload) {
  tile.value = new TileModel()
  tileType.value = payload
}
function setTileSize(payload) {
  tile.value.size = payload
}
function setDashboards(payload) {
  dashboardSelection.value = payload
}
watch(tileSubjectType, () => {
  tile.value.tile_data.type = tileSubjectType.value
})
watch(showAlert, () => {
  if (showAlert.value) {
    setTimeout(() => {
      showAlert.value = false
    }, 3000)
  }
})
</script>
<style>
.preview-container {
  position: relative;
}
</style>
