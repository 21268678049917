<template>
  <v-row align="center" justify="center" class="fill-height">
    <v-col class="px-4" cols="12">
      <div class="text-center pt-5">
        <v-icon large :color="trendColor">{{ `mdi-${trendIcon}` }}</v-icon>
      </div>
      <div class="text-center">
        <v-card-subtitle class="pb-1">
          {{ $lang.budget.consumption }}
        </v-card-subtitle>
        <v-card-subtitle class="pt-1">
          {{ useDateFormatter(startDate) }} - {{ useDateFormatter(endDate) }}
        </v-card-subtitle>
      </div>
      <div
        :class="`${trendColor}--text text-h4 text-center font-weight-bold px-2`"
      >
        {{ useCostFormatter(consumption) }}
      </div>
      <div class="text-center">
        <v-card-subtitle>
          {{ $lang.budget.on }}
          <b>{{ useCostFormatter(allocated) }}</b>
          {{ $lang.budget.allocated }}
        </v-card-subtitle>
      </div>
    </v-col>
    <v-col class="py-4"></v-col>
  </v-row>
</template>

<script>
import { budgetTile } from '@/mixins/budgetTile'

import { useCostFormatter } from '@/composables/cost'
import { useDateFormatter } from '@/composables/date'

export default {
  name: 'BudgetConsumptionTrend',

  data() {
    return {
      useCostFormatter: useCostFormatter,
      useDateFormatter: useDateFormatter,
    }
  },
  mixins: [budgetTile],
}
</script>
