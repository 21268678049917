<template>
  <v-row justify="center" class="fill-height">
    <v-col cols="12" md="4" align-self="start">
      <v-card color="transparent" flat>
        <v-card-text>
          <div class="subtitle-1">
            {{ $lang.dashboard.countResourcesIdWithTags }}
          </div>
          <div class="text-h5">
            {{ tagCoveringSummary.countResourcesIdWithTags }}
          </div>
        </v-card-text>
        <v-card-text>
          <div class="subtitle-1">
            {{ $lang.dashboard.totalCostWithTag }}
          </div>
          <div class="text-h5">
            {{ useCostFormatter(tagCoveringSummary.totalCostWithTag) }}
          </div>
        </v-card-text>
        <v-card-text>
          <div class="subtitle-1">
            {{ $lang.dashboard.percentTagsCovered }}
          </div>
          <div class="text-h5">
            {{ tagCoveringSummary.percentTagsCoverage | trend }}
            <v-icon x-large color="#0070d9">mdi-circle-medium</v-icon>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="4" align-self="center">
      <charts :options="chartOptions"></charts>
    </v-col>
    <v-col cols="12" md="4" align-self="start">
      <v-card color="transparent" flat>
        <v-card-text class="text-right">
          <div class="subtitle-1">
            {{ $lang.dashboard.countResourcesIdWithoutTags }}
          </div>
          <div class="text-h5">
            {{ tagCoveringSummary.countResourcesIdWithoutTags }}
          </div>
        </v-card-text>
        <v-card-text class="text-right">
          <div class="subtitle-1">
            {{ $lang.dashboard.totalCostWithoutTag }}
          </div>
          <div class="text-h5">
            {{ useCostFormatter(tagCoveringSummary.totalCostWithoutTag) }}
          </div>
        </v-card-text>
        <v-card-text class="text-right">
          <div class="subtitle-1">
            {{ $lang.dashboard.percentTagsUncovered }}
          </div>
          <div class="text-h5">
            <v-icon x-large color="#97d903">mdi-circle-medium</v-icon>
            {{ -(tagCoveringSummary.percentTagsCoverage - 100) | trend }}
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="py-4"></v-col>
  </v-row>
</template>

<script>
import { tileChart } from '@/mixins/tileChart'
import PipelineService from '@/services/pipeline'

import { useCostFormatter } from '@/composables/cost'

export default {
  name: 'ExplorerTagCoveringSummary',

  mixins: [tileChart],

  data() {
    return {
      useCostFormatter: useCostFormatter,
      pipelineService: new PipelineService(),
      tagCoveringSummary: {
        totalCostWithTag: 0,
        totalCostWithoutTag: 0,
        totalCost: 0,
        percentTagsCoverage: 0,
        countResourcesIdWithTags: '',
        countResourcesIdWithoutTags: '',
        countTags: '',
      },
    }
  },

  async mounted() {
    this.customChartOptions.chart.type = 'pie'
    this.customChartOptions.chart.height = '100%'
    await this.getTagCoveringSummary()
  },

  methods: {
    async getTagCoveringSummary() {
      const data = await this.pipelineService.aggregate(
        'tags/kpi',
        {
          from: this.data.start_date,
          to: this.data.end_date,
          scope: this.data.data_scope[0].blocks,
        },
        'GET',
      )

      if (data) {
        this.tagCoveringSummary = data
        this.setDistributionChartData(
          ['totalCostWithTag', 'totalCostWithoutTag'],
          data,
        )
      }
    },
  },

  watch: {
    data: {
      async handler(v) {
        await this.getTagCoveringSummary()
      },
    },
  },
}
</script>
